@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

/*
masterComponent
*/

/* マスターコンポーネント */
#page {
  background-color: map_get(variables.$COLOR, pale-gray);

  .animat-common {
    /* width: calc(800 / 1920 * 100vw); */
    max-width: 800px;
    min-width: 160px;
    position: absolute;
    right: 0;
    top: 280px;
    width: 41.6666666667vw;
    /* transform: translateY(-50%); */
    z-index: -1;

    @include mixin.tabletP {
      right: 0;
      top: 210px;
      width: 62vw;
      /* top: 200px; */
    }
  }
}


#pageContents {

  .page-sec-inner {
    padding: 56px 0 80px;

    @include mixin.tabletP {
      padding: 56px 0 88px;
    }
    @include mixin.sp {
      padding: 48px 0 72px;
    }
  }

  p {
    line-height: 1.8;

    &.p-notes {
      color: variables.$COLOR_TEXT_GRAY;
      font-size: 14px;
      line-height: 1.6;
      padding-left: 30px;
      position: relative;

      &::before {
        content: "※";
        display: inline-block;
        @include mixin.multiple-p(right, '2');
        position: absolute;
        top: 0;
        left: 0;
      }

      +.p-notes {
        @include mixin.multiple-m(top, '2');
      }
    }

    +p {
      @include mixin.multiple-m(top, '5');
    }

    +h2 {
      @include mixin.multiple-m(bottom, '20');

      @include mixin.tabletP {
        @include mixin.multiple-m(bottom, '18');
      }
    }

    +.h3-type2 {
      @include mixin.multiple-m(bottom, '15');
    }

    +h4 {
      @include mixin.multiple-m(top, '12');

      @include mixin.tabletP {
        @include mixin.multiple-m(top, '10');
      }
    }

    +.h5-num {
      @include mixin.multiple-m(top, '10');
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 700;
    letter-spacing: .02em;
  }

  h1 {
    font-size: calc(36px + 20 * (100vw - 375px) / 1545);
    line-height: 1.4;
    margin-top: 48px;
    margin-bottom: 48px;

    @include mixin.tabletP {
      margin-top: 48px;
      margin-bottom: 32px;
    }

    .h1-sub {
      display: block;
      font-size: calc(16px + 2 * (100vw - 375px) / 1545);
      font-weight: 700;
      line-height: 1.6;
      @include mixin.multiple-m(top, '3');

      @include mixin.tabletP {
        @include mixin.multiple-m(top, '1');
      }
    }

    &.page-ttl-en {
      color: variables.$COLOR_MAIN;
      font-family: variables.$FONT_ALPHA;
      font-size: calc(42px + 22 * (100vw - 375px) / 1545);
      font-weight: 900;
      line-height: 1.2;
      margin: 0;
      @include mixin.multiple-m(bottom, '1');

      @include mixin.sp {
        line-height: 1.1;
      }

    }

    &.page-ttl-ja {
      font-size: calc(22px + 14 * (100vw - 375px) / 1545);
      @include mixin.multiple-m(bottom, '5');

      @include mixin.tabletP {
        @include mixin.multiple-m(bottom, '2');
      }
    }
  }

  h2 {
    @include mixin.propertyClamp("font-size", 32, 38, 820, 1366);
    line-height: 1.6;
    @include mixin.multiple-m(bottom, '5');


    @include mixin.tabletP {
      @include mixin.propertyClamp("font-size", 28, 32, 600, 768);
      @include mixin.multiple-m(bottom, '5');
    }

    @include mixin.sp {
      @include mixin.multiple-m(bottom, '3');
    }

    &.h2-center {
      text-align: center;
    }
  }

  h3 {
    font-size: calc(26px + 6 * (100vw - 375px) / 1545);
    line-height: 1.6;
    @include mixin.multiple-m(bottom, '4');
    position: relative;

    @include mixin.tabletP {
      @include mixin.multiple-m(bottom, '3');
    }

    &::before {
      content: "";
      display: block;
    }

    &.quotation {
      text-align: center;
      margin-bottom: 0px;

      @include mixin.tabletP {
        @include mixin.multiple-m(bottom, '3');
      }

      span {
        display: inline-block;
        padding: 0 102px 8px;
        position: relative;

        @include mixin.tabletP {
          padding: 0;
        }

        // &::before,
        // &::after {
        //   background: url(#{mixin.set_common_path('icon_quotation.svg')}) no-repeat center center;
        //   background-size: 100% auto;
        //   content: "";
        //   display: block;
        //   width: 38px;
        //   height: 30px;
        //   position: absolute;

        //   @include mixin.tabletP {
        //     display: flex;
        //     flex-direction: column;
        //     width: 24px;
        //     height: 20px;
        //     position: static;
        //   }
        // }

        &::before {
          top: 0;
          left: 0;

          @include mixin.tabletP {
            top: auto;
            left: auto;
            margin-bottom: 4px;
            margin-left: -16px;
          }
        }


        &::after {
          bottom: 0;
          right: 0;
          transform: rotate(180deg);

          @include mixin.tabletP {
            bottom: auto;
            right: auto;
            margin-left: auto;
            margin-top: 6px;
            margin-right: -16px
          }
        }
      }


    }

    &.h3-type2 {
      padding-top: 16px;
      @include mixin.multiple-m(bottom, '4');

      @include mixin.tabletP {
        @include mixin.multiple-m(bottom, '4');
      }

      &::before {
        background-color: #ff8666;
        display: block;
        height: 6px;
        position: absolute;
        left: 0;
        top: 0;
        width: 56px;

        @include mixin.tabletP {
          width: 40px;
        }
      }

      span {
        display: block;
        color: variables.$COLOR_TEXT_GRAY;
        font-size: 16px;
        font-weight: 700;
        @include mixin.multiple-p(top, '3');
      }
    }

    &.h3-type3 {
      border-bottom: 1px solid #e5e5e5;
      padding-left: 78px;
      @include mixin.multiple-m(top, '10');
      @include mixin.multiple-m(bottom, '7');
      @include mixin.multiple-p(bottom, '4');

      @include mixin.tabletP {
        @include mixin.multiple-p(left, '7');
        @include mixin.multiple-p(bottom, '2');
        @include mixin.multiple-m(bottom, '4');
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
      }

      &::before {
        color: variables.$COLOR_MAIN;
        content: counter(num,decimal-leading-zero);
        counter-increment: num;
        display: inline-block;
        font-size: calc(32px + 16 * (100vw - 375px) / 1545);
        font-family: variables.$FONT_ALPHA;
        font-weight: 900;
        left: 0;
        line-height: normal;
      }

      &::after {
        background-color: variables.$COLOR_MAIN_ORANGE;
        display: block;
        width: 56px;
        height: 6px;
        position: absolute;
        bottom: 0;
        left: 0;

        @include mixin.tabletP {
          width: 48px
        }
      }
    }
  }

  h4 {
    font-size: calc(22px + 6 * (100vw - 375px) / 1545);
    line-height: 1.6;
    @include mixin.multiple-m(bottom, '5');

    @include mixin.tabletP {
      @include mixin.multiple-m(bottom, '3');
    }

    &.h4-icon {
      text-align: center;
      position: relative;

      &::before {
        background: url(#{mixin.set_common_path('icon_comment.svg')}) no-repeat center center;
        background-size: 100% auto;
        content: "";
        display: block;
        margin: 0 auto;
        width: 41px;
        height: 52px;
      }
    }
  }

  h5 {
    font-size: calc(22px + 2 * (100vw - 375px) / 1545);
    line-height: 1.6;
    @include mixin.multiple-m(top, '4');
    @include mixin.multiple-m(bottom, '1');
    position: relative;

    @include mixin.tabletP {
      @include mixin.multiple-m(bottom, '3');
    }

    &.fc-blue {
      color: variables.$COLOR_MAIN;
    }

    &.h5-num {
      border-bottom: 1px solid #e5e5e5;
      padding-left: 78px;
      @include mixin.multiple-p(bottom, '4');

      @include mixin.tabletP {
        @include mixin.multiple-p(left, '8');
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
      }

      &::before {
        color: variables.$COLOR_MAIN;
        content: "Q"counter(num)".";
        counter-increment: num;
        display: inline-block;
        font-size: calc(28px + 4 * (100vw - 375px) / 1545);
        font-family: variables.$FONT_ALPHA;
        font-weight: 900;
        left: 0;
        line-height: normal;
      }

      &::after {
        background-color: variables.$COLOR_MAIN_ORANGE;
        display: block;
        width: 56px;
        height: 6px;
        position: absolute;
        bottom: 0;
        left: 0;

        @include mixin.tabletP {
          width: 48px
        }
      }

      &.fc-blue {
        color: variables.$COLOR_MAIN;

        &::before {
          content: counter(num,decimal-leading-zero)".";
        }

        span {
          &::before {
            content: "0";
          }
        }
      }
    }
  }

  h6 {
    color: #FF00CE;
    display: block;
    font-size: calc(18px + 6 * (100vw - 375px) / 1545);
    line-height: 1.6;
    @include mixin.multiple-m(bottom, '3');
    width: 100%;
  }

  .count-unit {
    counter-reset: num;
  }

  .alignleft {
    display: inline;
    float: left;
    @include mixin.multiple-m(right, '3');
    @include mixin.multiple-m(bottom, '3');
  }

  .alignright {
    display: inline;
    float: right;
    @include mixin.multiple-m(left, '3');
    @include mixin.multiple-m(bottom, '3');
  }

  .aligncenter {
    @include mixin.multiple-m(bottom, '8');
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .c-olList,
  .c-olListNo,
  .c-faqList {

    li {
      +li {
        @include mixin.multiple-m(top, '8');
      }
    }

    .c-olList-ttl {
      // font-size: calc(26px + 6 * (100vw - 375px) / 1545);
      @include mixin.propertyClamp("font-size", 22, 30, 820, 1366);
      font-weight: 700;
      line-height: 1.6;
      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
      }

      span {
        display: block;
        font-size: calc(14px + 2 * (100vw - 375px) / 1545);
        @include mixin.multiple-m(top, '3');
      }
    }

    .c-olList-subttl {
      color: variables.$COLOR_MAIN;
      font-size: calc(22px + 2 * (100vw - 375px) / 1545);
      font-weight: 700;
      @include mixin.multiple-m(top, '8');

      @include mixin.tabletP {
        @include mixin.multiple-m(top, '4');
      }
    }

    &__inner {
      @include mixin.multiple-p(top, '4');

      @include mixin.tabletP {
        @include mixin.multiple-p(top, '3');
      }
    }
  }

  .c-olList {
    .c-olList-ttl {
      @include mixin.multiple-p(top, '5');

      &::before {
        background-color: variables.$COLOR_MAIN_ORANGE;
        display: block;
        width: 56px;
        height: 6px;
        top: 0;
        left: 0;
      }
    }
  }

  .c-olList,
  .c-olListNo {
    .c-olList-ttl {
      // font-size: calc(26px + 6 * (100vw - 375px) / 1545);
      @include mixin.propertyClamp("font-size", 22, 30, 820, 1366);

      @include mixin.tabletP {
        @include mixin.multiple-p(bottom, '3');
      }
    }
  }

  .c-olListNo,
  .c-faqList {
    counter-reset: num;

    .c-olList-ttl {
      border-bottom: 1px map_get(variables.$COLOR, gray) solid;
      padding-bottom: .5em;

      // @include mixin.tabletP {
      //   @include mixin.multiple-p(bottom, '3');
      // }

      &::before {
        content: counter(num, decimal-leading-zero);
        counter-increment: num;
        color: variables.$COLOR_MAIN;
        display: inline-block;
        font-family: variables.$FONT_ALPHA;
        font-weight: 900;
        line-height:normal;
        left: 0;
      }

      &::after {
        background-color: variables.$COLOR_MAIN_ORANGE;
        display: block;
        width: 56px;
        height: 6px;
        bottom: 0;
        left: 0;

        @include mixin.tabletP {
          width: 40px
        }
      }
    }
  }

  .c-olListNo {

    .c-olList-ttl {
      @include mixin.multiple-p(left, '12');

      @include mixin.tabletP {
        @include mixin.multiple-p(left, '6');
      }

      &::before {
        content: counter(num, decimal-leading-zero);
        // font-size: calc(32px + 16 * (100vw - 375px) / 1545);
        font-size: 1.25em;
        top: .13em;
        left: .13em;
      }
    }
  }


  .c-flowList {
    counter-reset: num;
    @include mixin.multiple-m(bottom, '4');

    &__inner {
      width: calc(100% - 64px);
      margin-left: auto;

      @include mixin.tabletP {
        margin: 0;
        width: 100%;
      }
    }

    li {
      border-top: 1px map_get(variables.$COLOR, gray) solid;
      border-bottom: 1px map_get(variables.$COLOR, gray) solid;
      padding: 56px 40px;
      position: relative;
      transition: .3s ease;

      @include mixin.tabletP {
        padding: 34px 0;
      }

      &::after {
        border-style: solid;
        border-width: 16px 12px 0 12px;
        border-color: #1847c7 transparent transparent transparent;
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        bottom: -16px;
        left: 50%;
        transform: translateX(-50%);
      }

      &:hover {
        @include mixin.tabletP-min {
          box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, .05);
          border-color: white;
          transition: .3s ease;
        }
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      +li {
        @include mixin.multiple-m(top, '4');
      }
    }

    .c-flowList-ttl {
      color: variables.$COLOR_MAIN;
      font-size: calc(22px + 2 * (100vw - 375px) / 1545);
      font-weight: 700;
      /* display: flex;
      align-items: center; */
      padding-left: 60px;
      @include mixin.multiple-m(bottom, '3');
      position: relative;

      @include mixin.tabletP {
        padding-left: 50px;
      }

      &::before {
        content: counter(num, decimal-leading-zero)".";
        counter-increment: num;
        display: inline-block;
        font-size: calc(28px + 4 * (100vw - 375px) / 1545);
        font-family: variables.$FONT_ALPHA;
        font-weight: 900;
        line-height: 1.2;
        /* padding-right: 16px; */
        width: 60px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        @include mixin.tabletP {
          width: 50px;
        }
      }
    }

    .c-flowList-txt {
      font-weight: 700;
      line-height: 1.8;

      a {
        color: variables.$COLOR_MAIN;
        text-decoration: none;
        position: relative;

        &::after {
          background-color: variables.$COLOR_MAIN;
          content: "";
          display: block;
          width: 100%;
          height: 1px;
        }
      }
    }
  }

  .c-faqList {
    .c-olList-ttl {
      font-size: calc(18px + 6 * (100vw - 375px) / 1545);
      @include mixin.multiple-p(left, '10');

      &::before {
        content: "Q" counter(num);
        font-size: calc(28px + 4 * (100vw - 375px) / 1545);
        top: 3px;
      }
    }
  }

  .c-notesList {
    li {
      color: variables.$COLOR_TEXT_GRAY;
      font-size: 14px;
      line-height: 1.6;
      padding-left: 30px;
      position: relative;

      &::before {
        content: "※";
        display: inline-block;
        @include mixin.multiple-p(right, '2');
        position: absolute;
        top: 0;
        left: 0;
      }

      +li {
        @include mixin.multiple-m(top, '2');
      }
    }
  }

  .c-list {
    li {
      line-height: 1.8;
      @include mixin.multiple-p(left, '3');
      position: relative;

      &::before {
        background-color: variables.$COLOR_MAIN;
        border-radius: 100%;
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        position: absolute;
        top: 17px;
        left: 0
      }

      +li {
        @include mixin.multiple-m(top, '2');
      }
    }
  }

  .page-header {

    .jumbotron-fluid {
      background-color: transparent;
      padding: 0;
      @include mixin.propertyClamp("padding-bottom", 32, 56, 960, 1920);// ブラウザ幅 960px-32px,1920px-56px
      margin-bottom: 0;
      position: relative;
      z-index: 1;

      @include mixin.tabletP {
        @include mixin.propertyClamp("padding-bottom", 64, 56, 375, 768);//ブラウザ幅 375px-56px,768px-56px
      }

      @include mixin.sp {
        padding-bottom: 64px;
      }

      &::before {
        background-color: variables.$COLOR_MAIN;
        content: "";
        display: block;
        width: 70%;
        height: 200px;
        position: absolute;
        /* bottom: 0;
        top: 50%;
        transform: translateY(calc(-50% + 64px));*/
        left: 0;
        z-index: 0;
        bottom: 0;
        top: auto;
        transform: translateY(0);

        @include mixin.tabletP {
          width: calc(100% - 32px);
          top: auto;
          bottom: 0;
          transform: translateY(0);
        }

        @include mixin.sp {
          width: calc(100% - 16px);
          height: 104px;
        }

      }

      .width-sm {
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);

        @include mixin.tabletP {
          width: calc(100% - 64px);
        }

        @include mixin.sp {
          width: calc(100% - 40px);
        }

        &::before {
          background-color: variables.$COLOR_MAIN_ORANGE;
          content: "";
          display: block;
          width: 72px;
          height: 6px;
          position: absolute;
          top: 0;
          @include mixin.propertyClamp("left", 24, 70, 920, 1366);//ブラウザ幅 920px-24px,1366px-70px

          @include mixin.tabletP {
            left: 16px;
          }
        }
      }

      .ttl-unit {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        padding: 56px 32px;
        @include mixin.propertyClamp("min-height", 275, 316, 920, 1980);//ブラウザ幅 920px-275px,1980px-316px
        width: 550px;
        max-width: 100%;
        z-index: 3;
        line-height: 1.4;

        @include mixin.tabletP {
          padding: 36px 16px 32px;
          width: calc(100% - 32px);
          @include mixin.propertyClamp("min-height", 180, 260, 375, 768);//ブラウザ幅 920px-275px,1980px-316px
        }

        @include mixin.sp {
          padding: 48px 16px 46px;
        }

        .page-ttl-ja {
          font-size: calc(16px + 16 * (100vw - 375px) / 1545);
          font-weight: 700;
          margin-top: 0;
        }

        .page-subttl-en {
          color: variables.$COLOR_MAIN;
          font-family: variables.$FONT_ALPHA;
          font-size: calc(20px + 4 * (100vw - 375px) / 1545);
          font-weight: 700;
          @include mixin.multiple-m(bottom, '3');

          &__sm {
            color: variables.$COLOR_TEXT_GRAY;
            font-family: variables.$FONT_ALPHA;
            font-size: 18px;
            line-height: 1;
            font-weight: 700;
            @include mixin.multiple-m(bottom, '1');
          }
        }

        h1 {
          font-size: calc(34px + 22 * (100vw - 375px) / 1545);

          &.page-ttl-ja {
            font-size: calc(22px + 10 * (100vw - 375px) / 1545);
            font-weight: 700;
            line-height: 1.4;

          }
        }
      }

      .col-left {
        position: absolute;
        bottom: auto;
        @include mixin.propertyClamp("top", 32, 124, 920, 1920);//ブラウザ幅 920px-32px,1920px-124px
        left: 0;
        z-index: 3;

        @include mixin.tabletP {
          top: 70%;
        }
      }

      .col-right {
        margin-left: auto;
        @include mixin.multiple-m(bottom, '4');
        position: relative;
        z-index: 2;

        @include mixin.tabletP {
          width: calc(100% - clamp(16px, 21.74vw + -70.96px, 96px));//clamp=ブラウザ幅 400px-16px,768px-96px
        }

        .col-img {
          padding: 0;
          @include mixin.propertyClamp("height", 280, 440, 920, 1920);//ブラウザ幅 920px-280px,1920px-440px

          @include mixin.tabletP {
            /* height: 580px; */
            height: auto;
          }

          &.c-ratio {
            overflow: hidden;

            &::before {
              padding-top: 42.391%;

              @include mixin.tabletP {
                display: none;
              }
            }

            img {
              width: 100%;
              height: 100%;

              @include mixin.tabletP {
                width: 100%;
                position: static;
                left: auto;
                right: auto;
              }
            }

            // .object-fit-img {

            //   @include mixin.tabletP {
            //     object-fit: unset;
            //   }
            // }
          }
        }
      }

      &.type2 {
        padding-bottom: 0;
        @include mixin.propertyClamp("padding-right", 20, 120, 920, 1920);//ブラウザ幅 920px-20px,1920px-120px
        width: 100%;
        position: initial;

        .ttl-unit {

        }

        @include mixin.tabletP {
          padding-right: 0;
          /* padding-bottom: 160px; */
        }

        &::before {
          height: 45%;
          z-index: -1;

          @include mixin.tabletP {
            bottom: -32px;
            width: 100%;
          }
        }

        .container-full {
          position: relative;
          @include mixin.propertyClamp("padding-bottom", 24, 40, 920, 1920);//ブラウザ幅 920px-24px,1920px-40px
          @include mixin.propertyClamp("padding-top", 24, 80, 920, 1366);//ブラウザ幅 920px-24px,1366px-80px

          @include mixin.tabletP {
            @include mixin.propertyClamp("padding-top", 220, 540, 375, 767);//ブラウザ幅 375px-220px,767px-540px
            padding-bottom: 0;
          }
        }

        .ttl-unit {
          @include mixin.propertyClamp("min-height", 275, 345, 960, 1366);//ブラウザ幅 960px-275px,1366px-345px

          @include mixin.tabletP {
            padding: 40px 24px;
            width: 100%;
          }

          p{
            line-height: 1.6;
          }

          .page-subttl-en{
            line-height: 1;
            @include mixin.multiple-m(bottom, '0.5');
          }

          .page-ttl-ja{
            @include mixin.multiple-m(bottom, '3');
          }

          .page-subttl-en__sm{
            @include mixin.multiple-m(bottom, '1');
            font-size: 16px;
          }

        }

        .col-left{
          position: relative;
          top: auto;
          bottom: auto;
          right: auto;
          z-index: auto;

          @include mixin.tabletP {
            bottom: 0;
          }

          &.width-sm {
            /* top: 32px; */
            @include mixin.propertyClamp("left", 20, 120, 960, 1920);//ブラウザ幅 960px-20px,1920px-120px

            @include mixin.tabletP {
              /* top: auto; */
              bottom: 0;
              left: 16px;
              width: calc(100% - 32px);

              @include mixin.sp {
                left: 0;
              }
            }

            &::before {
              left: 0;
            }

          }
        }

        .col-right {
          position: absolute;
          padding: 0;
          z-index: -1;
          top: 0;
          right: 0;
          width: 60%;

          @include mixin.tabletP {
            width: 100%;
            padding-left: 16px;
          }

          .col-img {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: clamp(300px, 22.42vw + 93.72px, 400px);//ブラウザ幅 920px-300px,1980px-400px
            overflow: hidden;

            @include mixin.tabletP {
              height: auto;
            }
          }
        }
      }

      &.type3 {
        height: 350px;

        .ttl-unit {
          padding: 0;
          width: 100%;
        }
      }

      &.type4 {
        padding: 0 16px;

        &::before {
          display: none;
        }

        .c-container-lg {
          width: 100%;
        }

        .ttl-unit {
          padding: 0;
          width: 100%;
          min-height: auto;
        }
      }

      .c-container-lg {
        padding: 0 24px;
      }
    }
  }

  .c-search-area {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;

    @include mixin.pc {
      gap: 8px;
    }

    @include mixin.sp {
      gap: 4px;
    }

    input[type="checkbox"],
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      width: auto;

      &.search__input {
        clip-path: rect(1px, 1px, 1px, 1px);

        &:checked + .search__label {
          background: variables.$COLOR_MAIN;
          border-color: variables.$COLOR_MAIN;
          color: white;
        }
      }
    }

    .search__label {
      background-color: white;
      border: 1px map_get(variables.$COLOR, gray) solid;
      color: variables.$COLOR_MAIN;
      display: inline-block;
      font-size: 14px;
      line-height: 1.2;
      padding: 8px  10px 8px;
      margin-bottom: 0;
      transition: all .2s ease;
      position: relative;

      @include mixin.sp {
        padding: 8px  8px 10px;
      }
    }
  }

  .c-list-unit {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 3px;

    li {
      text-align: center;

      a,
      span {
        display: block;
        @include mixin.fz(12);
        line-height: 1.2;
        padding: 4px 5px 5px;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }

        // @include mixin.sp {
        //   font-size: 13px;
        //   padding: 2px 6px 3px;
        // }
      }

      &.tag-item-black,
      &.tag-item-blue {
        a,
        span {
          background-color: white;
          border: 1px map_get(variables.$COLOR, gray) solid;

          &::before {
            content: "#";
            display: inline-block;
            padding-right: 2px;

            @include mixin.sp {
              padding-right: 3px;
            }
          }
        }
      }

      &.tag-item-blue {
        a,
        span {
          color: variables.$COLOR_MAIN;
        }
      }

      &.cate-item-black,
      &.cate-item-blue {
        a,
        span {
          color: white;
        }
      }

      &.cate-item-black {
        a,
        span {
          background-color: variables.$COLOR_TEXT;
        }
      }

      &.cate-item-blue {
        a,
        span {
          background-color: variables.$COLOR_MAIN;
        }
      }
    }

    a,
    span {
      display: inline-block;
      @include mixin.fz(12);
      line-height: 1.2;
      padding: 4px 5px 5px;
      text-decoration: none;

      // @include mixin.sp {
      //   font-size: 13px;
      //   padding: 2px 6px 3px;
      // }

      &:hover {
        text-decoration: none;
      }


      &.tag-item-black,
      &.tag-item-blue {
        background-color: white;
        border: 1px map_get(variables.$COLOR, gray) solid;

        &::before {
          content: "#";
          display: inline-block;
          padding-right: 2px;
        }
      }

      &.tag-item-blue {
        color: variables.$COLOR_MAIN;
      }

      &.cate-item-black,
      &.cate-item-blue {
        color: white;
      }

      &.cate-item-black {
        background-color: variables.$COLOR_TEXT;
      }

      &.cate-item-blue {
        background-color: variables.$COLOR_MAIN;

      }
    }
  }

  .nav-tabs {
    &.col3,
    &.col2 {
      justify-content: space-between;

      .nav-item {
        text-align: center;

        +.nav-item {
          border-left: 3px white solid;
        }
      }

      .nav-link {
        background-color: variables.$COLOR_MAIN;
        border: none;
        border-top: 3px variables.$COLOR_MAIN solid;
        border-radius: 0;
        color: #B8C3D9;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        @include mixin.propertyClamp("font-size", 14, 16, 960, 1366);
        font-weight: 700;
        line-height: 1.6;
        text-decoration: none;
        padding: 18px 16px;
        width: 100%;

        @include mixin.tabletP {
          padding: 16px;
        }

        span {
          display: inline-block;
          padding-left: 15px;
          position: relative;

          &::before {
            background: url(#{mixin.set_common_path('icon_arrow_tab_off.svg')}) no-repeat center center;
            background-size: 100% auto;
            content: "";
            display: block;
            width: 5px;
            height: 10px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }

        &.active {
          background-color: white;
          color: variables.$COLOR_MAIN;

          span {
            &::before {
              background: url(#{mixin.set_common_path('icon_arrow_tab_on.svg')}) no-repeat center center;
              background-size: 100% auto;
            }
          }
        }

        &:hover {
          background-color: white;
          color: variables.$COLOR_MAIN;

          span {
            &::before {
              background: url(#{mixin.set_common_path('icon_arrow_tab_on.svg')}) no-repeat center center;
              background-size: 100% auto;
            }
          }

          &.active {
            cursor: default;
            opacity: 1;
          }
        }
      }
    }

    &.col3 {

      .nav-item {
        width: calc(100% / 3);
      }
    }

    &.col2 {

      .nav-item {
        width: calc(100% / 2);
      }
    }

  }

  .tab-content-custom {
    padding: 96px 0;
  }

  .slider-unit {
    padding: 0 30px;
    position: relative;
    overflow: hidden;

    .slider-common-col3,
    .slider-common-col2 {

      .swiper-slide {

        img {
          width: 100%;
          max-width: 100%;
        }
      }

      .slider-card {
        text-decoration: none;

        >a {
          display: block;
          text-decoration: none;

          &:hover {
            [class^="cate-item"] {
              color: white;
            }
            .card-title,
            .tag-item-black  {
              color: variables.$COLOR_TEXT;
            }
          }
        }

        &:hover {
          [class^="cate-item"] {
            color: white;
          }
          .card-title,
          .tag-item-black  {
            color: variables.$COLOR_TEXT;
          }
        }

        .card-img {
          @include mixin.multiple-m(bottom, '2');
          width: 100%;
          max-width: 100%;
          overflow: hidden;
        }

        .card-title {
          font-weight: 700;
          line-height: 1.6;
          @include mixin.multiple-m(top, '1');
          @include mixin.multiple-m(bottom, '1.5');
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.08);
      height: 60px;
      width: 60px;
      opacity: 1;
      z-index: 1;

      &::after {
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        display: block;
        margin: auto;
        height: 60px;
        width: 60px;
      }
    }

    .swiper-button-prev {
      left: 0;

      @include mixin.tabletP {
        left: 16px;
      }

      &::after {
        background-image: url(#{mixin.set_common_path('slider_prev.svg')});
      }
    }

    .swiper-button-next {
      right: 0;

      @include mixin.tabletP {
        right: 16px;
      }

      &::after {
        background-image: url(#{mixin.set_common_path('slider_next.svg')});
      }
    }

    .slider-common-col3 {
      .slider-card {
        .card-img {

          &.c-ratio {
            &::before {
              padding-top: 100%;
            }
          }
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        top: 35%;
      }
    }

    .slider-common-col2 {
      .slider-card {
        background-color: white;

        .card-img {

          &.c-ratio {
            &::before {
              padding-top: 56%;
            }
          }
        }

        .card-body {
          padding: 40px 48px 24px;
          position: relative;
        }

        .card-title {
          line-height: 1.6;
          margin: 0;
        }

        .card-text {
          color: variables.$COLOR_MAIN;
          font-family: variables.$FONT_ALPHA;
          font-size: calc(14px + 2 * (100vw - 375px) / 1545);
          font-weight: 700;
          @include mixin.multiple-m(bottom, '2');
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        top: 30%;
      }
    }
  }

  .p-common-card-unit {
    box-sizing: border-box;
    display: flex;
    gap: 40px 8%;
    flex-wrap: wrap;

    @include mixin.tabletL {
      gap: 48px 4%;
    }

    @include mixin.sp {
      gap: 24px 16px;
    }

    .p-common-card {
      width: calc(100% / 3 - (8% / 3 * 2));

      @include mixin.tabletL {
        width: calc(100% / 2 - 2%)
      }

      @include mixin.sp {
        width: 100%;
      }

      &:hover {
        text-decoration: none;
        .card-title {
          color: variables.$COLOR_TEXT;
          text-decoration: none;

        }
      }
    }

    &.col2 {
      gap: 40px 4%;

      @include mixin.tabletP {
        gap: 40px 0;
      }

      .p-common-card {
        width: calc(100% / 2 - 2%);

        @include mixin.tabletP {
          width: 100%;
        }

        .card-title {
          font-size: 24px;
          margin-top: 0;
          line-height: 1.4;

          @include mixin.sp {
            font-size: 22px;
            margin-bottom: 8px;
          }
        }

        .card-text {
          line-height: 1.8;
          margin-top: 0;

          @include mixin.sp {
            line-height: 1.8;
          }
        }
      }
    }
  }

  .p-common-card {
    text-decoration: none;

    .card-img {
      // @include mixin.multiple-m(bottom, '2');
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      margin-bottom: 16px;

      &.c-ratio {
        &::before {
          padding-top: 100%;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .card-title {
      font-weight: 700;
      text-decoration: none;
      line-height: 1.6;
      @include mixin.multiple-m(top, '1');
      @include mixin.multiple-m(bottom, '1.5');
      width: 100%;
    }

    .card-text {
      line-height: 1.6;
    }

    .card-footer {
      padding: 0 40px 40px;
      text-align: right;

      @include mixin.tabletL {
        padding: 0 24px 24px;
      }
      @include mixin.sp {
        padding: 0 16px 24px;
      }
    }
  }

  .search-tbl {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;

    &__ttl {
      font-size: calc(16px + 2 * (100vw - 375px) / 1545);
      font-weight: 700;
    }
  }

  .gMap {
    display: inline-block;
    @include mixin.multiple-p(left, '3');
    text-decoration: none;
    position: relative;

    &::before {
      background: url(#{mixin.set_common_path('icon_map.svg')}) no-repeat center center;
      background-size: 100% auto;
      content: "";
      display: block;
      width: 14px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .p-btn {
    color: variables.$COLOR_MAIN;
    font-weight: 700;
    text-decoration: none;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    &__en {
      color: variables.$COLOR_MAIN;
      font-family: variables.$FONT_ALPHA;
      font-weight: 900;
      text-decoration: none;
    }

    &.btn-arrow-sm,
    &.btn-blank-sm {
      font-size: 14px;
    }

    &.btn-arrow {
      padding-right: 40px;

      &::after {
        background: url(#{mixin.set_common_path('icon_arrow_right_blue.svg')}) no-repeat center center;
        background-size: 100% auto;
        width: 24px;
        height: 20px;
      }

    }

    &.btn-arrow-sm {
      padding-right: 16px;

      &::after {
        background: url(#{mixin.set_common_path('icon_blank_blue_sm.svg')}) no-repeat center center;
        background-size: 100% auto;
        width: 10px;
        height: 16px;
      }
    }

    &.btn-blank {
      padding-right: 28px;

      &::after {
        background: url(#{mixin.set_common_path('icon_blank_blue_lg.svg')}) no-repeat center center;
        background-size: 100% auto;
        width: 20px;
        height: 20px;
      }
    }

    &.btn-blank-sm {
      padding-right: 20px;

      &::after {
        background: url(#{mixin.set_common_path('icon_blank_blue.svg')}) no-repeat center center;
        background-size: 100% auto;
        width: 12px;
        height: 12px;
      }
    }
  }

  .p-btn-lg {
    background-color: variables.$COLOR_MAIN;
    color: white;
    font-size: 18px;
    font-weight: 700;
    padding: 32px 24px;
    padding-right: 96px;
    text-align: left;
    line-height: 1.611;
    text-decoration: none;
    position: relative;

    @include mixin.tabletP {
      padding: 16px 24px;
      padding-right: 96px;
    }

    &::after {
      background: url(#{mixin.set_common_path('icon_arrow_right_white_lg.svg')}) no-repeat center center;
      background-size: 100% auto;
      content: "";
      display: block;
      width: 24px;
      height: 20px;
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
    }

    &.btn-blank {
      &::after {
        background: url(#{mixin.set_common_path('icon_blank_white_lg.svg')}) no-repeat center center;
        background-size: 100% auto;
        width: 26px;
        height: 26px;
      }
    }
  }

  .btn-reset {
    color: variables.$COLOR_MAIN;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  .filter-toggle,
  .btn-filter,
  .sp-nav-close {
    color: white;
    display: block;
    margin: 0 auto;
    text-align: left;
    text-decoration: none;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
    }
  }

  .filter-toggle,
  .btn-filter {
    background-color: variables.$COLOR_MAIN;
    padding: 24px;
    width: calc(100% - 32px);
    transition: width .5s;
  }

  .filter-toggle {
    @include mixin.multiple-m(bottom, '8');

    &::after {
      background: url(#{mixin.set_common_path('icon_filter.svg')}) no-repeat center center;
      background-size: 100% auto;
      width: 23px;
      height: 24px;
    }
  }

  .btn-filter {
    &::after {
      background: url(#{mixin.set_common_path('icon_filter_checked.svg')}) no-repeat center center;
      background-size: 100% auto;
      width: 22px;
      height: 22px;
    }
  }

  .sp-nav-close {
    background-color: variables.$COLOR_TEXT;
    padding: 16px;
    width: 100%;

    &::after {
      background: url(#{mixin.set_common_path('icon_close_white.svg')}) no-repeat center center;
      background-size: 100% auto;
      width: 13px;
      height: 13px;
    }
  }

  /* ==[ .p-common-info ]==================================== */
  .p-common-info {
    background-color: variables.$COLOR_MAIN;

    .sec-inner {
      padding: 56px 24px 0;

      @include mixin.tabletP {
        padding: 56px 18px 0;
      }

      @include mixin.sp {
        padding: 40px 18px 0;
      }
    }
    .card-unit {

      @include mixin.tabletP {

        &:nth-child(odd){
          padding: 0 8px 0 0;
          @media screen and (max-width: 575px) {
            padding: 0;
          }
        }
        &:nth-child(even) {
          padding: 0 0 0 8px;
          @media screen and (max-width: 575px) {
            padding: 0;
          }
        }
      }

      +.card-unit {

        @media screen and (max-width: 575px) {
          margin-top: 16px;
        }
      }
    }

    .info-card {
      .card-img {
        text-align: center;
        position: relative;
        overflow: hidden;

        img {
          position: relative;
          transition: .3s;
        }

        .info-ttl {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          @include mixin.sp {
            width: 70%;
          }

          &__img {
            width: auto;
            max-width: 100%;
          }
        }
      }

      &:hover {
        opacity: 1;

        .card-img {
          img:not(.info-ttl__img) {
            transform: scale(1.2);
            transition: .8s;
          }
        }
      }
    }
  }

}
/*
#people {
  #pageContents {
    .jumbotron-fluid {

      .col-right {

        .col-img {
          height: 610px;
        }
      }
    }

    .page-header {

      .jumbotron-fluid {
        &::before {
          bottom: 0;
          top: 50%;
          transform: translateY(calc(-50% + 64px));
        }
      }
    }
  }
}
*/
.filter-toggle-wrap {
  position: static;
  top: auto;
  left: auto;

  &.is-fixed {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 1050;

    .filter-toggle {
      width: 100% !important;
      transform: width .5s;
    }
  }
}

.sp-container {
  display: none;

  @include mixin.tabletP {
    display: block;
  }

  .sp-filter-menu-container {
    background-color: white;
    padding: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 7000;
    overflow-y: auto;
    transform: translateY(-120%);
    transition: .5s ease;

    &.panelactive {
      display: block;
      transform: translateY(0);
      transition: .5s ease;
    }
  }

  .sp-filter-menu {
    height: 100vh;
    position: relative;

    .sp-nav-header {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .sp-nav-body {
      padding: 88px 24px 160px;
      height: 100%;
      overflow-y: auto;
    }

    .sp-nav-footer {
      background-color: white;
      border-top: 1px rgba(34, 34, 34, .2) solid;
      padding: 16px;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }

  .p-btn-unit {

    @include mixin.tabletP {
      text-align: center;
      @include mixin.multiple-m(bottom, '10');
    }
  }
}

.c-notes-box {
  background-color: map_get(variables.$COLOR, pale-gray);
  padding: 32px 56px;
  margin-bottom: 32px;

  @include mixin.tabletP {
    padding: 32px 24px;
  }

  @include mixin.sp {
    margin-bottom: 16px;
  }

  .c-notes-ttl {
    color: variables.$COLOR_MAIN;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.6;
    @include mixin.multiple-m(bottom, '3');
  }

  .c-notes-txt {
    color: variables.$COLOR_TEXT;
    font-size: 16px;
  }
}

.g-slide {
  margin: 15px 0 20px 0;
  padding-bottom: 50%;
  padding-top: 65px;
  position: relative;
  overflow: hidden;

  iframe,
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  +h3 {
    @include mixin.multiple-m(top, '15');
  }
}

.c-anc-unit {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 0 56px;
  padding: 0;

  @include mixin.tabletP {
    gap: 0 40px;
  }

  @include mixin.sp {
    padding: 0;
  }

  li {
    width: calc(100% / 2 - 28px);

    @include mixin.tabletP {
      width: calc(100% / 2 - 20px);
    }
  }

  .anc-link {
    border-bottom: 1px variables.$COLOR_MAIN solid;
    display: flex;
    align-items: center;
    width: 100%;
    @include mixin.propertyClamp("font-size", 16, 20, 960, 1280);//ブラウザ幅 960px-16px,1280px-20px
    font-weight: 700;
    padding: 0 24px 16px 0;
    text-decoration: none;
    line-height: 1.3;
    position: relative;

    @include mixin.tabletP {
      padding: 0 16px 24px 0;
    }

    &::after {
      background: url(../img/common/icon_arrow_right_blue.svg) no-repeat 50%;
      background-size: 100% auto;
      content: "";
      display: flex;
      align-items: center;
      height: 20px;
      width: 24px;
      position: absolute;
      top: 8px;
      right: 0;
      transform: rotate(90deg);

      @include mixin.tabletP {
        height: 15px;
        width: 18px;
      }
    }

    &:hover {
      color: variables.$COLOR_TEXT;
    }
  }

  &.col4{
    gap: 0 20px;
    @include mixin.tabletP {
      gap: 0 10px;
    }
    li{
      display: flex;
      width: calc(100% / 4 - 15px);

      @include mixin.tabletP {
        width: calc(100% / 4 - 8px);
      }
      @include mixin.sp {
        width: calc(100% / 2 - 8px);
      }
    }
  }
}

#work {
  .page-header {
    .jumbotron-fluid {
      &::before {
        top: auto;
        bottom: 0;
        transform: translateY(0);
      }

      .col-right {
        .col-img {
          @include mixin.propertyClamp("height", 280, 440, 920, 1920);//ブラウザ幅 920px-280px,1920px-440px
          @include mixin.tabletP {
            height: auto;
          }
        }
      }
    }
  }
}



/* 20241114追加 */
.myBlockCategory {
  &__group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.417;

      @include mixin.tabletP {
        font-size: 10px;
      }
    }

    + .myBlockCategory__group {
      margin-top: 7px;

      @include mixin.tabletP {
        margin-top: 4px;
      }
    }

    &.-group01 {
      gap: 4px;

      @include mixin.tabletP {
        gap: 2px;
      }

      li {
        background-color: white;
        border: 1px solid #6D6D6D;
        color: #6D6D6D;
        padding: 4px 8px;

        @include mixin.tabletP {
          padding: 2px 6px;
        }

        &.-blue {
          background-color: variables.$COLOR_MAIN;
          border-color: variables.$COLOR_MAIN;
          color: white;
        }

        &.-black {
          background-color: variables.$COLOR_TEXT;
          border-color: variables.$COLOR_TEXT;
          color: white;
        }
      }
    }

    &.-group02 {
      gap: 0 12px;

      @include mixin.tabletP {
        gap: 0 10px;
      }

      li {
        color: #929292;
        position: relative;

        &::before {
          content: "#";
          display: inline-block;
        }

        &.-map {

          &::before {
            display: none;
          }

        }
      }
    }
  }

  &__gMap {
    color: #929292;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.417;
    padding-left: 14px;
    text-decoration: none;
    position: relative;

    @include mixin.tabletP {
      font-size: 10px;
      padding-left: 10px;
    }

    &::before {
      background: url(#{mixin.set_common_path('icon_map.svg')}) no-repeat center center;
      background-size: 100% auto;
      content: "";
      display: block;
      height: 15px;
      width: 10px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      @include mixin.tabletP {
        height: 10px;
        width: 7px;
      }
    }
  }
}

.myBlockTag {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 12px;

  @include mixin.tabletP {
    gap: 4px;
  }

  &__item  {
    border: 1px transparent solid;
    color: white;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 4px 8px;

    @include mixin.tabletP {
      padding: 5px 6px;
      font-size: 10px;
    }

    &.-blue {
      background-color: variables.$COLOR_MAIN;
      border-color: variables.$COLOR_MAIN;
      color: white;
    }

    &.-black {
      background-color: variables.$COLOR_TEXT;
      border-color: variables.$COLOR_TEXT;
      color: white;
    }

    &.-white {
      background-color: white;
      border: 1px map_get(variables.$COLOR, gray) solid;
      color: variables.$COLOR_TEXT;
    }
  }
}

.myBlockResult {
  margin-bottom: 32px;

  @include mixin.tabletP {
    margin-bottom: 30PX;
  }

  &__num {
    margin-bottom: 20px;

    &-text {
      color: variables.$COLOR_MAIN;
      display: flex;
      align-items: baseline;
      gap: 0 2px;
      font-family: 'Lato', sans-serif;
      font-size: 32px;
      font-weight: 700;
      line-height: 1;
      position: relative;

      &::after {
        color: #000;
        content: "件の求人";
        display: inline-block;
        font-family: variables.$FONT_BASE;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  &__tag {
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    gap: 0 8px;
    position: relative;

    @include mixin.tabletP {
      gap: 10px 0;
      flex-wrap: wrap;
    }

    span,
    button {
      display: inline-block;
      vertical-align: middle;
    }

    &-header {
      line-height: 1;
      width: 100px;

      @include mixin.tabletP {
        line-height: normal;
        width: 100%;
      }
    }

    &-title {
      color: #000;
      font-size: 14px;
    }

    &-body {
      box-sizing: border-box;
      display: flex;
      gap: 8px 6px;
      flex-wrap: wrap;
      width: calc(100% - 116px);
      position: relative;

      @include mixin.tabletP {
        gap: 4px;
        width: 100%;
      }
    }

    &-item {
      border: 1px solid #516894;
      display: flex;
      align-items: center;
      gap: 0 3px;
      justify-content: space-between;
      padding: 5px 10px;
      padding-right: 0;
    }

    &-text {
      color: #516894;
      font-size: 12px;

      @include mixin.tabletP {
        font-size: 11px;
      }
    }

    &-delete {
      height: 20px;
      width: 20px;
      position: relative;

      &::before,
      &::after {
        background-color: #516894;
        content: "";
        display: block;
        height: 1px;
        width: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }


    &-reset {
      margin-top: 2px;
      width: 100%;
    }
  }

}