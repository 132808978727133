@charset "UTF-8";
/* 余白 8の倍数 */
/* clampを使用し、指定したプロパティをレスポンシブに可変 */
/*
masterComponent
*/
/* マスターコンポーネント */
#page {
  background-color: #F9F9F9;
}
#page .animat-common {
  /* width: calc(800 / 1920 * 100vw); */
  max-width: 800px;
  min-width: 160px;
  position: absolute;
  right: 0;
  top: 280px;
  width: 41.6666666667vw;
  /* transform: translateY(-50%); */
  z-index: -1;
}
@media not all and (min-width: 768px) {
  #page .animat-common {
    right: 0;
    top: 210px;
    width: 62vw;
    /* top: 200px; */
  }
}

#pageContents {
  /* ==[ .p-common-info ]==================================== */
}
#pageContents .page-sec-inner {
  padding: 56px 0 80px;
}
@media not all and (min-width: 768px) {
  #pageContents .page-sec-inner {
    padding: 56px 0 88px;
  }
}
@media not all and (min-width: 600px) {
  #pageContents .page-sec-inner {
    padding: 48px 0 72px;
  }
}
#pageContents p {
  line-height: 1.8;
}
#pageContents p.p-notes {
  color: #9c9c9c;
  font-size: 14px;
  line-height: 1.6;
  padding-left: 30px;
  position: relative;
}
#pageContents p.p-notes::before {
  content: "※";
  display: inline-block;
  padding-right: calc( 8px * 2 );
  position: absolute;
  top: 0;
  left: 0;
}
#pageContents p.p-notes + .p-notes {
  margin-top: calc( 8px * 2 );
}
#pageContents p + p {
  margin-top: calc( 8px * 5 );
}
#pageContents p + h2 {
  margin-bottom: calc( 8px * 20 );
}
@media not all and (min-width: 768px) {
  #pageContents p + h2 {
    margin-bottom: calc( 8px * 18 );
  }
}
#pageContents p + .h3-type2 {
  margin-bottom: calc( 8px * 15 );
}
#pageContents p + h4 {
  margin-top: calc( 8px * 12 );
}
@media not all and (min-width: 768px) {
  #pageContents p + h4 {
    margin-top: calc( 8px * 10 );
  }
}
#pageContents p + .h5-num {
  margin-top: calc( 8px * 10 );
}
#pageContents h1,
#pageContents h2,
#pageContents h3,
#pageContents h4,
#pageContents h5 {
  font-weight: 700;
  letter-spacing: 0.02em;
}
#pageContents h1 {
  font-size: calc(36px + 20 * (100vw - 375px) / 1545);
  line-height: 1.4;
  margin-top: 48px;
  margin-bottom: 48px;
}
@media not all and (min-width: 768px) {
  #pageContents h1 {
    margin-top: 48px;
    margin-bottom: 32px;
  }
}
#pageContents h1 .h1-sub {
  display: block;
  font-size: calc(16px + 2 * (100vw - 375px) / 1545);
  font-weight: 700;
  line-height: 1.6;
  margin-top: calc( 8px * 3 );
}
@media not all and (min-width: 768px) {
  #pageContents h1 .h1-sub {
    margin-top: calc( 8px * 1 );
  }
}
#pageContents h1.page-ttl-en {
  color: #1847c7;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-size: calc(42px + 22 * (100vw - 375px) / 1545);
  font-weight: 900;
  line-height: 1.2;
  margin: 0;
  margin-bottom: calc( 8px * 1 );
}
@media not all and (min-width: 600px) {
  #pageContents h1.page-ttl-en {
    line-height: 1.1;
  }
}
#pageContents h1.page-ttl-ja {
  font-size: calc(22px + 14 * (100vw - 375px) / 1545);
  margin-bottom: calc( 8px * 5 );
}
@media not all and (min-width: 768px) {
  #pageContents h1.page-ttl-ja {
    margin-bottom: calc( 8px * 2 );
  }
}
#pageContents h2 {
  font-size: clamp(32px, 1.1vw + 22.99px, 38px);
  line-height: 1.6;
  margin-bottom: calc( 8px * 5 );
}
@media not all and (min-width: 768px) {
  #pageContents h2 {
    font-size: clamp(28px, 2.38vw + 13.71px, 32px);
    margin-bottom: calc( 8px * 5 );
  }
}
@media not all and (min-width: 600px) {
  #pageContents h2 {
    margin-bottom: calc( 8px * 3 );
  }
}
#pageContents h2.h2-center {
  text-align: center;
}
#pageContents h3 {
  font-size: calc(26px + 6 * (100vw - 375px) / 1545);
  line-height: 1.6;
  margin-bottom: calc( 8px * 4 );
  position: relative;
}
@media not all and (min-width: 768px) {
  #pageContents h3 {
    margin-bottom: calc( 8px * 3 );
  }
}
#pageContents h3::before {
  content: "";
  display: block;
}
#pageContents h3.quotation {
  text-align: center;
  margin-bottom: 0px;
}
@media not all and (min-width: 768px) {
  #pageContents h3.quotation {
    margin-bottom: calc( 8px * 3 );
  }
}
#pageContents h3.quotation span {
  display: inline-block;
  padding: 0 102px 8px;
  position: relative;
}
@media not all and (min-width: 768px) {
  #pageContents h3.quotation span {
    padding: 0;
  }
}
#pageContents h3.quotation span::before {
  top: 0;
  left: 0;
}
@media not all and (min-width: 768px) {
  #pageContents h3.quotation span::before {
    top: auto;
    left: auto;
    margin-bottom: 4px;
    margin-left: -16px;
  }
}
#pageContents h3.quotation span::after {
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}
@media not all and (min-width: 768px) {
  #pageContents h3.quotation span::after {
    bottom: auto;
    right: auto;
    margin-left: auto;
    margin-top: 6px;
    margin-right: -16px;
  }
}
#pageContents h3.h3-type2 {
  padding-top: 16px;
  margin-bottom: calc( 8px * 4 );
}
@media not all and (min-width: 768px) {
  #pageContents h3.h3-type2 {
    margin-bottom: calc( 8px * 4 );
  }
}
#pageContents h3.h3-type2::before {
  background-color: #ff8666;
  display: block;
  height: 6px;
  position: absolute;
  left: 0;
  top: 0;
  width: 56px;
}
@media not all and (min-width: 768px) {
  #pageContents h3.h3-type2::before {
    width: 40px;
  }
}
#pageContents h3.h3-type2 span {
  display: block;
  color: #9c9c9c;
  font-size: 16px;
  font-weight: 700;
  padding-top: calc( 8px * 3 );
}
#pageContents h3.h3-type3 {
  border-bottom: 1px solid #e5e5e5;
  padding-left: 78px;
  margin-top: calc( 8px * 10 );
  margin-bottom: calc( 8px * 7 );
  padding-bottom: calc( 8px * 4 );
}
@media not all and (min-width: 768px) {
  #pageContents h3.h3-type3 {
    padding-left: calc( 8px * 7 );
    padding-bottom: calc( 8px * 2 );
    margin-bottom: calc( 8px * 4 );
  }
}
#pageContents h3.h3-type3::before, #pageContents h3.h3-type3::after {
  content: "";
  position: absolute;
}
#pageContents h3.h3-type3::before {
  color: #1847c7;
  content: counter(num, decimal-leading-zero);
  counter-increment: num;
  display: inline-block;
  font-size: calc(32px + 16 * (100vw - 375px) / 1545);
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-weight: 900;
  left: 0;
  line-height: normal;
}
#pageContents h3.h3-type3::after {
  background-color: #ff7f00;
  display: block;
  width: 56px;
  height: 6px;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media not all and (min-width: 768px) {
  #pageContents h3.h3-type3::after {
    width: 48px;
  }
}
#pageContents h4 {
  font-size: calc(22px + 6 * (100vw - 375px) / 1545);
  line-height: 1.6;
  margin-bottom: calc( 8px * 5 );
}
@media not all and (min-width: 768px) {
  #pageContents h4 {
    margin-bottom: calc( 8px * 3 );
  }
}
#pageContents h4.h4-icon {
  text-align: center;
  position: relative;
}
#pageContents h4.h4-icon::before {
  background: url(../img/common/icon_comment.svg) no-repeat center center;
  background-size: 100% auto;
  content: "";
  display: block;
  margin: 0 auto;
  width: 41px;
  height: 52px;
}
#pageContents h5 {
  font-size: calc(22px + 2 * (100vw - 375px) / 1545);
  line-height: 1.6;
  margin-top: calc( 8px * 4 );
  margin-bottom: calc( 8px * 1 );
  position: relative;
}
@media not all and (min-width: 768px) {
  #pageContents h5 {
    margin-bottom: calc( 8px * 3 );
  }
}
#pageContents h5.fc-blue {
  color: #1847c7;
}
#pageContents h5.h5-num {
  border-bottom: 1px solid #e5e5e5;
  padding-left: 78px;
  padding-bottom: calc( 8px * 4 );
}
@media not all and (min-width: 768px) {
  #pageContents h5.h5-num {
    padding-left: calc( 8px * 8 );
  }
}
#pageContents h5.h5-num::before, #pageContents h5.h5-num::after {
  content: "";
  position: absolute;
}
#pageContents h5.h5-num::before {
  color: #1847c7;
  content: "Q" counter(num) ".";
  counter-increment: num;
  display: inline-block;
  font-size: calc(28px + 4 * (100vw - 375px) / 1545);
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-weight: 900;
  left: 0;
  line-height: normal;
}
#pageContents h5.h5-num::after {
  background-color: #ff7f00;
  display: block;
  width: 56px;
  height: 6px;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media not all and (min-width: 768px) {
  #pageContents h5.h5-num::after {
    width: 48px;
  }
}
#pageContents h5.h5-num.fc-blue {
  color: #1847c7;
}
#pageContents h5.h5-num.fc-blue::before {
  content: counter(num, decimal-leading-zero) ".";
}
#pageContents h5.h5-num.fc-blue span::before {
  content: "0";
}
#pageContents h6 {
  color: #FF00CE;
  display: block;
  font-size: calc(18px + 6 * (100vw - 375px) / 1545);
  line-height: 1.6;
  margin-bottom: calc( 8px * 3 );
  width: 100%;
}
#pageContents .count-unit {
  counter-reset: num;
}
#pageContents .alignleft {
  display: inline;
  float: left;
  margin-right: calc( 8px * 3 );
  margin-bottom: calc( 8px * 3 );
}
#pageContents .alignright {
  display: inline;
  float: right;
  margin-left: calc( 8px * 3 );
  margin-bottom: calc( 8px * 3 );
}
#pageContents .aligncenter {
  margin-bottom: calc( 8px * 8 );
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
#pageContents .c-olList li + li,
#pageContents .c-olListNo li + li,
#pageContents .c-faqList li + li {
  margin-top: calc( 8px * 8 );
}
#pageContents .c-olList .c-olList-ttl,
#pageContents .c-olListNo .c-olList-ttl,
#pageContents .c-faqList .c-olList-ttl {
  font-size: clamp(22px, 1.47vw + 9.99px, 30px);
  font-weight: 700;
  line-height: 1.6;
  position: relative;
}
#pageContents .c-olList .c-olList-ttl::before, #pageContents .c-olList .c-olList-ttl::after,
#pageContents .c-olListNo .c-olList-ttl::before,
#pageContents .c-olListNo .c-olList-ttl::after,
#pageContents .c-faqList .c-olList-ttl::before,
#pageContents .c-faqList .c-olList-ttl::after {
  content: "";
  position: absolute;
}
#pageContents .c-olList .c-olList-ttl span,
#pageContents .c-olListNo .c-olList-ttl span,
#pageContents .c-faqList .c-olList-ttl span {
  display: block;
  font-size: calc(14px + 2 * (100vw - 375px) / 1545);
  margin-top: calc( 8px * 3 );
}
#pageContents .c-olList .c-olList-subttl,
#pageContents .c-olListNo .c-olList-subttl,
#pageContents .c-faqList .c-olList-subttl {
  color: #1847c7;
  font-size: calc(22px + 2 * (100vw - 375px) / 1545);
  font-weight: 700;
  margin-top: calc( 8px * 8 );
}
@media not all and (min-width: 768px) {
  #pageContents .c-olList .c-olList-subttl,
#pageContents .c-olListNo .c-olList-subttl,
#pageContents .c-faqList .c-olList-subttl {
    margin-top: calc( 8px * 4 );
  }
}
#pageContents .c-olList__inner,
#pageContents .c-olListNo__inner,
#pageContents .c-faqList__inner {
  padding-top: calc( 8px * 4 );
}
@media not all and (min-width: 768px) {
  #pageContents .c-olList__inner,
#pageContents .c-olListNo__inner,
#pageContents .c-faqList__inner {
    padding-top: calc( 8px * 3 );
  }
}
#pageContents .c-olList .c-olList-ttl {
  padding-top: calc( 8px * 5 );
}
#pageContents .c-olList .c-olList-ttl::before {
  background-color: #ff7f00;
  display: block;
  width: 56px;
  height: 6px;
  top: 0;
  left: 0;
}
#pageContents .c-olList .c-olList-ttl,
#pageContents .c-olListNo .c-olList-ttl {
  font-size: clamp(22px, 1.47vw + 9.99px, 30px);
}
@media not all and (min-width: 768px) {
  #pageContents .c-olList .c-olList-ttl,
#pageContents .c-olListNo .c-olList-ttl {
    padding-bottom: calc( 8px * 3 );
  }
}
#pageContents .c-olListNo,
#pageContents .c-faqList {
  counter-reset: num;
}
#pageContents .c-olListNo .c-olList-ttl,
#pageContents .c-faqList .c-olList-ttl {
  border-bottom: 1px #E5E5E5 solid;
  padding-bottom: 0.5em;
}
#pageContents .c-olListNo .c-olList-ttl::before,
#pageContents .c-faqList .c-olList-ttl::before {
  content: counter(num, decimal-leading-zero);
  counter-increment: num;
  color: #1847c7;
  display: inline-block;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-weight: 900;
  line-height: normal;
  left: 0;
}
#pageContents .c-olListNo .c-olList-ttl::after,
#pageContents .c-faqList .c-olList-ttl::after {
  background-color: #ff7f00;
  display: block;
  width: 56px;
  height: 6px;
  bottom: 0;
  left: 0;
}
@media not all and (min-width: 768px) {
  #pageContents .c-olListNo .c-olList-ttl::after,
#pageContents .c-faqList .c-olList-ttl::after {
    width: 40px;
  }
}
#pageContents .c-olListNo .c-olList-ttl {
  padding-left: calc( 8px * 12 );
}
@media not all and (min-width: 768px) {
  #pageContents .c-olListNo .c-olList-ttl {
    padding-left: calc( 8px * 6 );
  }
}
#pageContents .c-olListNo .c-olList-ttl::before {
  content: counter(num, decimal-leading-zero);
  font-size: 1.25em;
  top: 0.13em;
  left: 0.13em;
}
#pageContents .c-flowList {
  counter-reset: num;
  margin-bottom: calc( 8px * 4 );
}
#pageContents .c-flowList__inner {
  width: calc(100% - 64px);
  margin-left: auto;
}
@media not all and (min-width: 768px) {
  #pageContents .c-flowList__inner {
    margin: 0;
    width: 100%;
  }
}
#pageContents .c-flowList li {
  border-top: 1px #E5E5E5 solid;
  border-bottom: 1px #E5E5E5 solid;
  padding: 56px 40px;
  position: relative;
  transition: 0.3s ease;
}
@media not all and (min-width: 768px) {
  #pageContents .c-flowList li {
    padding: 34px 0;
  }
}
#pageContents .c-flowList li::after {
  border-style: solid;
  border-width: 16px 12px 0 12px;
  border-color: #1847c7 transparent transparent transparent;
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  #pageContents .c-flowList li:hover {
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.05);
    border-color: white;
    transition: 0.3s ease;
  }
}
#pageContents .c-flowList li:last-child::after {
  display: none;
}
#pageContents .c-flowList li + li {
  margin-top: calc( 8px * 4 );
}
#pageContents .c-flowList .c-flowList-ttl {
  color: #1847c7;
  font-size: calc(22px + 2 * (100vw - 375px) / 1545);
  font-weight: 700;
  /* display: flex;
  align-items: center; */
  padding-left: 60px;
  margin-bottom: calc( 8px * 3 );
  position: relative;
}
@media not all and (min-width: 768px) {
  #pageContents .c-flowList .c-flowList-ttl {
    padding-left: 50px;
  }
}
#pageContents .c-flowList .c-flowList-ttl::before {
  content: counter(num, decimal-leading-zero) ".";
  counter-increment: num;
  display: inline-block;
  font-size: calc(28px + 4 * (100vw - 375px) / 1545);
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-weight: 900;
  line-height: 1.2;
  /* padding-right: 16px; */
  width: 60px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media not all and (min-width: 768px) {
  #pageContents .c-flowList .c-flowList-ttl::before {
    width: 50px;
  }
}
#pageContents .c-flowList .c-flowList-txt {
  font-weight: 700;
  line-height: 1.8;
}
#pageContents .c-flowList .c-flowList-txt a {
  color: #1847c7;
  text-decoration: none;
  position: relative;
}
#pageContents .c-flowList .c-flowList-txt a::after {
  background-color: #1847c7;
  content: "";
  display: block;
  width: 100%;
  height: 1px;
}
#pageContents .c-faqList .c-olList-ttl {
  font-size: calc(18px + 6 * (100vw - 375px) / 1545);
  padding-left: calc( 8px * 10 );
}
#pageContents .c-faqList .c-olList-ttl::before {
  content: "Q" counter(num);
  font-size: calc(28px + 4 * (100vw - 375px) / 1545);
  top: 3px;
}
#pageContents .c-notesList li {
  color: #9c9c9c;
  font-size: 14px;
  line-height: 1.6;
  padding-left: 30px;
  position: relative;
}
#pageContents .c-notesList li::before {
  content: "※";
  display: inline-block;
  padding-right: calc( 8px * 2 );
  position: absolute;
  top: 0;
  left: 0;
}
#pageContents .c-notesList li + li {
  margin-top: calc( 8px * 2 );
}
#pageContents .c-list li {
  line-height: 1.8;
  padding-left: calc( 8px * 3 );
  position: relative;
}
#pageContents .c-list li::before {
  background-color: #1847c7;
  border-radius: 100%;
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 17px;
  left: 0;
}
#pageContents .c-list li + li {
  margin-top: calc( 8px * 2 );
}
#pageContents .page-header .jumbotron-fluid {
  background-color: transparent;
  padding: 0;
  padding-bottom: clamp(32px, 2.5vw + 8px, 56px);
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid {
    padding-bottom: clamp(64px, -2.04vw + 71.63px, 56px);
  }
}
@media not all and (min-width: 600px) {
  #pageContents .page-header .jumbotron-fluid {
    padding-bottom: 64px;
  }
}
#pageContents .page-header .jumbotron-fluid::before {
  background-color: #1847c7;
  content: "";
  display: block;
  width: 70%;
  height: 200px;
  position: absolute;
  /* bottom: 0;
  top: 50%;
  transform: translateY(calc(-50% + 64px));*/
  left: 0;
  z-index: 0;
  bottom: 0;
  top: auto;
  transform: translateY(0);
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid::before {
    width: calc(100% - 32px);
    top: auto;
    bottom: 0;
    transform: translateY(0);
  }
}
@media not all and (min-width: 600px) {
  #pageContents .page-header .jumbotron-fluid::before {
    width: calc(100% - 16px);
    height: 104px;
  }
}
#pageContents .page-header .jumbotron-fluid .width-sm {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid .width-sm {
    width: calc(100% - 64px);
  }
}
@media not all and (min-width: 600px) {
  #pageContents .page-header .jumbotron-fluid .width-sm {
    width: calc(100% - 40px);
  }
}
#pageContents .page-header .jumbotron-fluid .width-sm::before {
  background-color: #ff7f00;
  content: "";
  display: block;
  width: 72px;
  height: 6px;
  position: absolute;
  top: 0;
  left: clamp(24px, 10.31vw + -70.89px, 70px);
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid .width-sm::before {
    left: 16px;
  }
}
#pageContents .page-header .jumbotron-fluid .ttl-unit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 56px 32px;
  min-height: clamp(275px, 3.87vw + 239.42px, 316px);
  width: 550px;
  max-width: 100%;
  z-index: 3;
  line-height: 1.4;
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid .ttl-unit {
    padding: 36px 16px 32px;
    width: calc(100% - 32px);
    min-height: clamp(180px, 20.36vw + 103.66px, 260px);
  }
}
@media not all and (min-width: 600px) {
  #pageContents .page-header .jumbotron-fluid .ttl-unit {
    padding: 48px 16px 46px;
  }
}
#pageContents .page-header .jumbotron-fluid .ttl-unit .page-ttl-ja {
  font-size: calc(16px + 16 * (100vw - 375px) / 1545);
  font-weight: 700;
  margin-top: 0;
}
#pageContents .page-header .jumbotron-fluid .ttl-unit .page-subttl-en {
  color: #1847c7;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-size: calc(20px + 4 * (100vw - 375px) / 1545);
  font-weight: 700;
  margin-bottom: calc( 8px * 3 );
}
#pageContents .page-header .jumbotron-fluid .ttl-unit .page-subttl-en__sm {
  color: #9c9c9c;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: calc( 8px * 1 );
}
#pageContents .page-header .jumbotron-fluid .ttl-unit h1 {
  font-size: calc(34px + 22 * (100vw - 375px) / 1545);
}
#pageContents .page-header .jumbotron-fluid .ttl-unit h1.page-ttl-ja {
  font-size: calc(22px + 10 * (100vw - 375px) / 1545);
  font-weight: 700;
  line-height: 1.4;
}
#pageContents .page-header .jumbotron-fluid .col-left {
  position: absolute;
  bottom: auto;
  top: clamp(32px, 9.2vw + -52.64px, 124px);
  left: 0;
  z-index: 3;
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid .col-left {
    top: 70%;
  }
}
#pageContents .page-header .jumbotron-fluid .col-right {
  margin-left: auto;
  margin-bottom: calc( 8px * 4 );
  position: relative;
  z-index: 2;
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid .col-right {
    width: calc(100% - clamp(16px, 21.74vw - 70.96px, 96px));
  }
}
#pageContents .page-header .jumbotron-fluid .col-right .col-img {
  padding: 0;
  height: clamp(280px, 16vw + 132.8px, 440px);
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid .col-right .col-img {
    /* height: 580px; */
    height: auto;
  }
}
#pageContents .page-header .jumbotron-fluid .col-right .col-img.c-ratio {
  overflow: hidden;
}
#pageContents .page-header .jumbotron-fluid .col-right .col-img.c-ratio::before {
  padding-top: 42.391%;
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid .col-right .col-img.c-ratio::before {
    display: none;
  }
}
#pageContents .page-header .jumbotron-fluid .col-right .col-img.c-ratio img {
  width: 100%;
  height: 100%;
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid .col-right .col-img.c-ratio img {
    width: 100%;
    position: static;
    left: auto;
    right: auto;
  }
}
#pageContents .page-header .jumbotron-fluid.type2 {
  padding-bottom: 0;
  padding-right: clamp(20px, 10vw + -72px, 120px);
  width: 100%;
  position: initial;
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid.type2 {
    padding-right: 0;
    /* padding-bottom: 160px; */
  }
}
#pageContents .page-header .jumbotron-fluid.type2::before {
  height: 45%;
  z-index: -1;
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid.type2::before {
    bottom: -32px;
    width: 100%;
  }
}
#pageContents .page-header .jumbotron-fluid.type2 .container-full {
  position: relative;
  padding-bottom: clamp(24px, 1.6vw + 9.28px, 40px);
  padding-top: clamp(24px, 12.56vw + -91.52px, 80px);
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid.type2 .container-full {
    padding-top: clamp(220px, 81.63vw + -86.12px, 540px);
    padding-bottom: 0;
  }
}
#pageContents .page-header .jumbotron-fluid.type2 .ttl-unit {
  min-height: clamp(275px, 17.24vw + 109.48px, 345px);
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid.type2 .ttl-unit {
    padding: 40px 24px;
    width: 100%;
  }
}
#pageContents .page-header .jumbotron-fluid.type2 .ttl-unit p {
  line-height: 1.6;
}
#pageContents .page-header .jumbotron-fluid.type2 .ttl-unit .page-subttl-en {
  line-height: 1;
  margin-bottom: calc( 8px * 0.5 );
}
#pageContents .page-header .jumbotron-fluid.type2 .ttl-unit .page-ttl-ja {
  margin-bottom: calc( 8px * 3 );
}
#pageContents .page-header .jumbotron-fluid.type2 .ttl-unit .page-subttl-en__sm {
  margin-bottom: calc( 8px * 1 );
  font-size: 16px;
}
#pageContents .page-header .jumbotron-fluid.type2 .col-left {
  position: relative;
  top: auto;
  bottom: auto;
  right: auto;
  z-index: auto;
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid.type2 .col-left {
    bottom: 0;
  }
}
#pageContents .page-header .jumbotron-fluid.type2 .col-left.width-sm {
  /* top: 32px; */
  left: clamp(20px, 10.42vw + -80px, 120px);
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid.type2 .col-left.width-sm {
    /* top: auto; */
    bottom: 0;
    left: 16px;
    width: calc(100% - 32px);
  }
  @media not all and (min-width: 600px) {
    #pageContents .page-header .jumbotron-fluid.type2 .col-left.width-sm {
      left: 0;
    }
  }
}
#pageContents .page-header .jumbotron-fluid.type2 .col-left.width-sm::before {
  left: 0;
}
#pageContents .page-header .jumbotron-fluid.type2 .col-right {
  position: absolute;
  padding: 0;
  z-index: -1;
  top: 0;
  right: 0;
  width: 60%;
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid.type2 .col-right {
    width: 100%;
    padding-left: 16px;
  }
}
#pageContents .page-header .jumbotron-fluid.type2 .col-right .col-img {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: clamp(300px, 22.42vw + 93.72px, 400px);
  overflow: hidden;
}
@media not all and (min-width: 768px) {
  #pageContents .page-header .jumbotron-fluid.type2 .col-right .col-img {
    height: auto;
  }
}
#pageContents .page-header .jumbotron-fluid.type3 {
  height: 350px;
}
#pageContents .page-header .jumbotron-fluid.type3 .ttl-unit {
  padding: 0;
  width: 100%;
}
#pageContents .page-header .jumbotron-fluid.type4 {
  padding: 0 16px;
}
#pageContents .page-header .jumbotron-fluid.type4::before {
  display: none;
}
#pageContents .page-header .jumbotron-fluid.type4 .c-container-lg {
  width: 100%;
}
#pageContents .page-header .jumbotron-fluid.type4 .ttl-unit {
  padding: 0;
  width: 100%;
  min-height: auto;
}
#pageContents .page-header .jumbotron-fluid .c-container-lg {
  padding: 0 24px;
}
#pageContents .c-search-area {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}
@media not all and (min-width: 1200px) {
  #pageContents .c-search-area {
    gap: 8px;
  }
}
@media not all and (min-width: 600px) {
  #pageContents .c-search-area {
    gap: 4px;
  }
}
#pageContents .c-search-area input[type=checkbox],
#pageContents .c-search-area input[type=radio] {
  position: absolute;
  opacity: 0;
  width: auto;
}
#pageContents .c-search-area input[type=checkbox].search__input,
#pageContents .c-search-area input[type=radio].search__input {
  clip-path: rect(1px, 1px, 1px, 1px);
}
#pageContents .c-search-area input[type=checkbox].search__input:checked + .search__label,
#pageContents .c-search-area input[type=radio].search__input:checked + .search__label {
  background: #1847c7;
  border-color: #1847c7;
  color: white;
}
#pageContents .c-search-area .search__label {
  background-color: white;
  border: 1px #E5E5E5 solid;
  color: #1847c7;
  display: inline-block;
  font-size: 14px;
  line-height: 1.2;
  padding: 8px 10px 8px;
  margin-bottom: 0;
  transition: all 0.2s ease;
  position: relative;
}
@media not all and (min-width: 600px) {
  #pageContents .c-search-area .search__label {
    padding: 8px 8px 10px;
  }
}
#pageContents .c-list-unit {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
}
#pageContents .c-list-unit li {
  text-align: center;
}
#pageContents .c-list-unit li a,
#pageContents .c-list-unit li span {
  display: block;
  font-size: 0.75rem;
  line-height: 1.2;
  padding: 4px 5px 5px;
  text-decoration: none;
}
#pageContents .c-list-unit li a:hover,
#pageContents .c-list-unit li span:hover {
  text-decoration: none;
}
#pageContents .c-list-unit li.tag-item-black a,
#pageContents .c-list-unit li.tag-item-black span, #pageContents .c-list-unit li.tag-item-blue a,
#pageContents .c-list-unit li.tag-item-blue span {
  background-color: white;
  border: 1px #E5E5E5 solid;
}
#pageContents .c-list-unit li.tag-item-black a::before,
#pageContents .c-list-unit li.tag-item-black span::before, #pageContents .c-list-unit li.tag-item-blue a::before,
#pageContents .c-list-unit li.tag-item-blue span::before {
  content: "#";
  display: inline-block;
  padding-right: 2px;
}
@media not all and (min-width: 600px) {
  #pageContents .c-list-unit li.tag-item-black a::before,
#pageContents .c-list-unit li.tag-item-black span::before, #pageContents .c-list-unit li.tag-item-blue a::before,
#pageContents .c-list-unit li.tag-item-blue span::before {
    padding-right: 3px;
  }
}
#pageContents .c-list-unit li.tag-item-blue a,
#pageContents .c-list-unit li.tag-item-blue span {
  color: #1847c7;
}
#pageContents .c-list-unit li.cate-item-black a,
#pageContents .c-list-unit li.cate-item-black span, #pageContents .c-list-unit li.cate-item-blue a,
#pageContents .c-list-unit li.cate-item-blue span {
  color: white;
}
#pageContents .c-list-unit li.cate-item-black a,
#pageContents .c-list-unit li.cate-item-black span {
  background-color: #222;
}
#pageContents .c-list-unit li.cate-item-blue a,
#pageContents .c-list-unit li.cate-item-blue span {
  background-color: #1847c7;
}
#pageContents .c-list-unit a,
#pageContents .c-list-unit span {
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1.2;
  padding: 4px 5px 5px;
  text-decoration: none;
}
#pageContents .c-list-unit a:hover,
#pageContents .c-list-unit span:hover {
  text-decoration: none;
}
#pageContents .c-list-unit a.tag-item-black, #pageContents .c-list-unit a.tag-item-blue,
#pageContents .c-list-unit span.tag-item-black,
#pageContents .c-list-unit span.tag-item-blue {
  background-color: white;
  border: 1px #E5E5E5 solid;
}
#pageContents .c-list-unit a.tag-item-black::before, #pageContents .c-list-unit a.tag-item-blue::before,
#pageContents .c-list-unit span.tag-item-black::before,
#pageContents .c-list-unit span.tag-item-blue::before {
  content: "#";
  display: inline-block;
  padding-right: 2px;
}
#pageContents .c-list-unit a.tag-item-blue,
#pageContents .c-list-unit span.tag-item-blue {
  color: #1847c7;
}
#pageContents .c-list-unit a.cate-item-black, #pageContents .c-list-unit a.cate-item-blue,
#pageContents .c-list-unit span.cate-item-black,
#pageContents .c-list-unit span.cate-item-blue {
  color: white;
}
#pageContents .c-list-unit a.cate-item-black,
#pageContents .c-list-unit span.cate-item-black {
  background-color: #222;
}
#pageContents .c-list-unit a.cate-item-blue,
#pageContents .c-list-unit span.cate-item-blue {
  background-color: #1847c7;
}
#pageContents .nav-tabs.col3, #pageContents .nav-tabs.col2 {
  justify-content: space-between;
}
#pageContents .nav-tabs.col3 .nav-item, #pageContents .nav-tabs.col2 .nav-item {
  text-align: center;
}
#pageContents .nav-tabs.col3 .nav-item + .nav-item, #pageContents .nav-tabs.col2 .nav-item + .nav-item {
  border-left: 3px white solid;
}
#pageContents .nav-tabs.col3 .nav-link, #pageContents .nav-tabs.col2 .nav-link {
  background-color: #1847c7;
  border: none;
  border-top: 3px #1847c7 solid;
  border-radius: 0;
  color: #B8C3D9;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: clamp(14px, 0.49vw + 9.27px, 16px);
  font-weight: 700;
  line-height: 1.6;
  text-decoration: none;
  padding: 18px 16px;
  width: 100%;
}
@media not all and (min-width: 768px) {
  #pageContents .nav-tabs.col3 .nav-link, #pageContents .nav-tabs.col2 .nav-link {
    padding: 16px;
  }
}
#pageContents .nav-tabs.col3 .nav-link span, #pageContents .nav-tabs.col2 .nav-link span {
  display: inline-block;
  padding-left: 15px;
  position: relative;
}
#pageContents .nav-tabs.col3 .nav-link span::before, #pageContents .nav-tabs.col2 .nav-link span::before {
  background: url(../img/common/icon_arrow_tab_off.svg) no-repeat center center;
  background-size: 100% auto;
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
#pageContents .nav-tabs.col3 .nav-link.active, #pageContents .nav-tabs.col2 .nav-link.active {
  background-color: white;
  color: #1847c7;
}
#pageContents .nav-tabs.col3 .nav-link.active span::before, #pageContents .nav-tabs.col2 .nav-link.active span::before {
  background: url(../img/common/icon_arrow_tab_on.svg) no-repeat center center;
  background-size: 100% auto;
}
#pageContents .nav-tabs.col3 .nav-link:hover, #pageContents .nav-tabs.col2 .nav-link:hover {
  background-color: white;
  color: #1847c7;
}
#pageContents .nav-tabs.col3 .nav-link:hover span::before, #pageContents .nav-tabs.col2 .nav-link:hover span::before {
  background: url(../img/common/icon_arrow_tab_on.svg) no-repeat center center;
  background-size: 100% auto;
}
#pageContents .nav-tabs.col3 .nav-link:hover.active, #pageContents .nav-tabs.col2 .nav-link:hover.active {
  cursor: default;
  opacity: 1;
}
#pageContents .nav-tabs.col3 .nav-item {
  width: 33.3333333333%;
}
#pageContents .nav-tabs.col2 .nav-item {
  width: 50%;
}
#pageContents .tab-content-custom {
  padding: 96px 0;
}
#pageContents .slider-unit {
  padding: 0 30px;
  position: relative;
  overflow: hidden;
}
#pageContents .slider-unit .slider-common-col3 .swiper-slide img,
#pageContents .slider-unit .slider-common-col2 .swiper-slide img {
  width: 100%;
  max-width: 100%;
}
#pageContents .slider-unit .slider-common-col3 .slider-card,
#pageContents .slider-unit .slider-common-col2 .slider-card {
  text-decoration: none;
}
#pageContents .slider-unit .slider-common-col3 .slider-card > a,
#pageContents .slider-unit .slider-common-col2 .slider-card > a {
  display: block;
  text-decoration: none;
}
#pageContents .slider-unit .slider-common-col3 .slider-card > a:hover [class^=cate-item],
#pageContents .slider-unit .slider-common-col2 .slider-card > a:hover [class^=cate-item] {
  color: white;
}
#pageContents .slider-unit .slider-common-col3 .slider-card > a:hover .card-title,
#pageContents .slider-unit .slider-common-col3 .slider-card > a:hover .tag-item-black,
#pageContents .slider-unit .slider-common-col2 .slider-card > a:hover .card-title,
#pageContents .slider-unit .slider-common-col2 .slider-card > a:hover .tag-item-black {
  color: #222;
}
#pageContents .slider-unit .slider-common-col3 .slider-card:hover [class^=cate-item],
#pageContents .slider-unit .slider-common-col2 .slider-card:hover [class^=cate-item] {
  color: white;
}
#pageContents .slider-unit .slider-common-col3 .slider-card:hover .card-title,
#pageContents .slider-unit .slider-common-col3 .slider-card:hover .tag-item-black,
#pageContents .slider-unit .slider-common-col2 .slider-card:hover .card-title,
#pageContents .slider-unit .slider-common-col2 .slider-card:hover .tag-item-black {
  color: #222;
}
#pageContents .slider-unit .slider-common-col3 .slider-card .card-img,
#pageContents .slider-unit .slider-common-col2 .slider-card .card-img {
  margin-bottom: calc( 8px * 2 );
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
#pageContents .slider-unit .slider-common-col3 .slider-card .card-title,
#pageContents .slider-unit .slider-common-col2 .slider-card .card-title {
  font-weight: 700;
  line-height: 1.6;
  margin-top: calc( 8px * 1 );
  margin-bottom: calc( 8px * 1.5 );
}
#pageContents .slider-unit .swiper-button-prev,
#pageContents .slider-unit .swiper-button-next {
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.08);
  height: 60px;
  width: 60px;
  opacity: 1;
  z-index: 1;
}
#pageContents .slider-unit .swiper-button-prev::after,
#pageContents .slider-unit .swiper-button-next::after {
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: block;
  margin: auto;
  height: 60px;
  width: 60px;
}
#pageContents .slider-unit .swiper-button-prev {
  left: 0;
}
@media not all and (min-width: 768px) {
  #pageContents .slider-unit .swiper-button-prev {
    left: 16px;
  }
}
#pageContents .slider-unit .swiper-button-prev::after {
  background-image: url(../img/common/slider_prev.svg);
}
#pageContents .slider-unit .swiper-button-next {
  right: 0;
}
@media not all and (min-width: 768px) {
  #pageContents .slider-unit .swiper-button-next {
    right: 16px;
  }
}
#pageContents .slider-unit .swiper-button-next::after {
  background-image: url(../img/common/slider_next.svg);
}
#pageContents .slider-unit .slider-common-col3 .slider-card .card-img.c-ratio::before {
  padding-top: 100%;
}
#pageContents .slider-unit .slider-common-col3 .swiper-button-prev,
#pageContents .slider-unit .slider-common-col3 .swiper-button-next {
  top: 35%;
}
#pageContents .slider-unit .slider-common-col2 .slider-card {
  background-color: white;
}
#pageContents .slider-unit .slider-common-col2 .slider-card .card-img.c-ratio::before {
  padding-top: 56%;
}
#pageContents .slider-unit .slider-common-col2 .slider-card .card-body {
  padding: 40px 48px 24px;
  position: relative;
}
#pageContents .slider-unit .slider-common-col2 .slider-card .card-title {
  line-height: 1.6;
  margin: 0;
}
#pageContents .slider-unit .slider-common-col2 .slider-card .card-text {
  color: #1847c7;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-size: calc(14px + 2 * (100vw - 375px) / 1545);
  font-weight: 700;
  margin-bottom: calc( 8px * 2 );
}
#pageContents .slider-unit .slider-common-col2 .swiper-button-prev,
#pageContents .slider-unit .slider-common-col2 .swiper-button-next {
  top: 30%;
}
#pageContents .p-common-card-unit {
  box-sizing: border-box;
  display: flex;
  gap: 40px 8%;
  flex-wrap: wrap;
}
@media not all and (min-width: 992px) {
  #pageContents .p-common-card-unit {
    gap: 48px 4%;
  }
}
@media not all and (min-width: 600px) {
  #pageContents .p-common-card-unit {
    gap: 24px 16px;
  }
}
#pageContents .p-common-card-unit .p-common-card {
  width: 28%;
}
@media not all and (min-width: 992px) {
  #pageContents .p-common-card-unit .p-common-card {
    width: 48%;
  }
}
@media not all and (min-width: 600px) {
  #pageContents .p-common-card-unit .p-common-card {
    width: 100%;
  }
}
#pageContents .p-common-card-unit .p-common-card:hover {
  text-decoration: none;
}
#pageContents .p-common-card-unit .p-common-card:hover .card-title {
  color: #222;
  text-decoration: none;
}
#pageContents .p-common-card-unit.col2 {
  gap: 40px 4%;
}
@media not all and (min-width: 768px) {
  #pageContents .p-common-card-unit.col2 {
    gap: 40px 0;
  }
}
#pageContents .p-common-card-unit.col2 .p-common-card {
  width: 48%;
}
@media not all and (min-width: 768px) {
  #pageContents .p-common-card-unit.col2 .p-common-card {
    width: 100%;
  }
}
#pageContents .p-common-card-unit.col2 .p-common-card .card-title {
  font-size: 24px;
  margin-top: 0;
  line-height: 1.4;
}
@media not all and (min-width: 600px) {
  #pageContents .p-common-card-unit.col2 .p-common-card .card-title {
    font-size: 22px;
    margin-bottom: 8px;
  }
}
#pageContents .p-common-card-unit.col2 .p-common-card .card-text {
  line-height: 1.8;
  margin-top: 0;
}
@media not all and (min-width: 600px) {
  #pageContents .p-common-card-unit.col2 .p-common-card .card-text {
    line-height: 1.8;
  }
}
#pageContents .p-common-card {
  text-decoration: none;
}
#pageContents .p-common-card .card-img {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 16px;
}
#pageContents .p-common-card .card-img.c-ratio::before {
  padding-top: 100%;
}
#pageContents .p-common-card .card-img.c-ratio img {
  width: 100%;
  height: 100%;
}
#pageContents .p-common-card .card-title {
  font-weight: 700;
  text-decoration: none;
  line-height: 1.6;
  margin-top: calc( 8px * 1 );
  margin-bottom: calc( 8px * 1.5 );
  width: 100%;
}
#pageContents .p-common-card .card-text {
  line-height: 1.6;
}
#pageContents .p-common-card .card-footer {
  padding: 0 40px 40px;
  text-align: right;
}
@media not all and (min-width: 992px) {
  #pageContents .p-common-card .card-footer {
    padding: 0 24px 24px;
  }
}
@media not all and (min-width: 600px) {
  #pageContents .p-common-card .card-footer {
    padding: 0 16px 24px;
  }
}
#pageContents .search-tbl {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
}
#pageContents .search-tbl__ttl {
  font-size: calc(16px + 2 * (100vw - 375px) / 1545);
  font-weight: 700;
}
#pageContents .gMap {
  display: inline-block;
  padding-left: calc( 8px * 3 );
  text-decoration: none;
  position: relative;
}
#pageContents .gMap::before {
  background: url(../img/common/icon_map.svg) no-repeat center center;
  background-size: 100% auto;
  content: "";
  display: block;
  width: 14px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
#pageContents .p-btn {
  color: #1847c7;
  font-weight: 700;
  text-decoration: none;
  position: relative;
}
#pageContents .p-btn::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
#pageContents .p-btn__en {
  color: #1847c7;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-weight: 900;
  text-decoration: none;
}
#pageContents .p-btn.btn-arrow-sm, #pageContents .p-btn.btn-blank-sm {
  font-size: 14px;
}
#pageContents .p-btn.btn-arrow {
  padding-right: 40px;
}
#pageContents .p-btn.btn-arrow::after {
  background: url(../img/common/icon_arrow_right_blue.svg) no-repeat center center;
  background-size: 100% auto;
  width: 24px;
  height: 20px;
}
#pageContents .p-btn.btn-arrow-sm {
  padding-right: 16px;
}
#pageContents .p-btn.btn-arrow-sm::after {
  background: url(../img/common/icon_blank_blue_sm.svg) no-repeat center center;
  background-size: 100% auto;
  width: 10px;
  height: 16px;
}
#pageContents .p-btn.btn-blank {
  padding-right: 28px;
}
#pageContents .p-btn.btn-blank::after {
  background: url(../img/common/icon_blank_blue_lg.svg) no-repeat center center;
  background-size: 100% auto;
  width: 20px;
  height: 20px;
}
#pageContents .p-btn.btn-blank-sm {
  padding-right: 20px;
}
#pageContents .p-btn.btn-blank-sm::after {
  background: url(../img/common/icon_blank_blue.svg) no-repeat center center;
  background-size: 100% auto;
  width: 12px;
  height: 12px;
}
#pageContents .p-btn-lg {
  background-color: #1847c7;
  color: white;
  font-size: 18px;
  font-weight: 700;
  padding: 32px 24px;
  padding-right: 96px;
  text-align: left;
  line-height: 1.611;
  text-decoration: none;
  position: relative;
}
@media not all and (min-width: 768px) {
  #pageContents .p-btn-lg {
    padding: 16px 24px;
    padding-right: 96px;
  }
}
#pageContents .p-btn-lg::after {
  background: url(../img/common/icon_arrow_right_white_lg.svg) no-repeat center center;
  background-size: 100% auto;
  content: "";
  display: block;
  width: 24px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}
#pageContents .p-btn-lg.btn-blank::after {
  background: url(../img/common/icon_blank_white_lg.svg) no-repeat center center;
  background-size: 100% auto;
  width: 26px;
  height: 26px;
}
#pageContents .btn-reset {
  color: #1847c7;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}
#pageContents .btn-reset:hover {
  text-decoration: underline;
}
#pageContents .filter-toggle,
#pageContents .btn-filter,
#pageContents .sp-nav-close {
  color: white;
  display: block;
  margin: 0 auto;
  text-align: left;
  text-decoration: none;
  position: relative;
}
#pageContents .filter-toggle::after,
#pageContents .btn-filter::after,
#pageContents .sp-nav-close::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}
#pageContents .filter-toggle,
#pageContents .btn-filter {
  background-color: #1847c7;
  padding: 24px;
  width: calc(100% - 32px);
  transition: width 0.5s;
}
#pageContents .filter-toggle {
  margin-bottom: calc( 8px * 8 );
}
#pageContents .filter-toggle::after {
  background: url(../img/common/icon_filter.svg) no-repeat center center;
  background-size: 100% auto;
  width: 23px;
  height: 24px;
}
#pageContents .btn-filter::after {
  background: url(../img/common/icon_filter_checked.svg) no-repeat center center;
  background-size: 100% auto;
  width: 22px;
  height: 22px;
}
#pageContents .sp-nav-close {
  background-color: #222;
  padding: 16px;
  width: 100%;
}
#pageContents .sp-nav-close::after {
  background: url(../img/common/icon_close_white.svg) no-repeat center center;
  background-size: 100% auto;
  width: 13px;
  height: 13px;
}
#pageContents .p-common-info {
  background-color: #1847c7;
}
#pageContents .p-common-info .sec-inner {
  padding: 56px 24px 0;
}
@media not all and (min-width: 768px) {
  #pageContents .p-common-info .sec-inner {
    padding: 56px 18px 0;
  }
}
@media not all and (min-width: 600px) {
  #pageContents .p-common-info .sec-inner {
    padding: 40px 18px 0;
  }
}
@media not all and (min-width: 768px) {
  #pageContents .p-common-info .card-unit:nth-child(odd) {
    padding: 0 8px 0 0;
  }
  @media screen and (max-width: 575px) {
    #pageContents .p-common-info .card-unit:nth-child(odd) {
      padding: 0;
    }
  }
  #pageContents .p-common-info .card-unit:nth-child(even) {
    padding: 0 0 0 8px;
  }
  @media screen and (max-width: 575px) {
    #pageContents .p-common-info .card-unit:nth-child(even) {
      padding: 0;
    }
  }
}
@media screen and (max-width: 575px) {
  #pageContents .p-common-info .card-unit + .card-unit {
    margin-top: 16px;
  }
}
#pageContents .p-common-info .info-card .card-img {
  text-align: center;
  position: relative;
  overflow: hidden;
}
#pageContents .p-common-info .info-card .card-img img {
  position: relative;
  transition: 0.3s;
}
#pageContents .p-common-info .info-card .card-img .info-ttl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media not all and (min-width: 600px) {
  #pageContents .p-common-info .info-card .card-img .info-ttl {
    width: 70%;
  }
}
#pageContents .p-common-info .info-card .card-img .info-ttl__img {
  width: auto;
  max-width: 100%;
}
#pageContents .p-common-info .info-card:hover {
  opacity: 1;
}
#pageContents .p-common-info .info-card:hover .card-img img:not(.info-ttl__img) {
  transform: scale(1.2);
  transition: 0.8s;
}

/*
#people {
  #pageContents {
    .jumbotron-fluid {

      .col-right {

        .col-img {
          height: 610px;
        }
      }
    }

    .page-header {

      .jumbotron-fluid {
        &::before {
          bottom: 0;
          top: 50%;
          transform: translateY(calc(-50% + 64px));
        }
      }
    }
  }
}
*/
.filter-toggle-wrap {
  position: static;
  top: auto;
  left: auto;
}
.filter-toggle-wrap.is-fixed {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 1050;
}
.filter-toggle-wrap.is-fixed .filter-toggle {
  width: 100% !important;
  transform: width 0.5s;
}

.sp-container {
  display: none;
}
@media not all and (min-width: 768px) {
  .sp-container {
    display: block;
  }
}
.sp-container .sp-filter-menu-container {
  background-color: white;
  padding: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7000;
  overflow-y: auto;
  transform: translateY(-120%);
  transition: 0.5s ease;
}
.sp-container .sp-filter-menu-container.panelactive {
  display: block;
  transform: translateY(0);
  transition: 0.5s ease;
}
.sp-container .sp-filter-menu {
  height: 100vh;
  position: relative;
}
.sp-container .sp-filter-menu .sp-nav-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.sp-container .sp-filter-menu .sp-nav-body {
  padding: 88px 24px 160px;
  height: 100%;
  overflow-y: auto;
}
.sp-container .sp-filter-menu .sp-nav-footer {
  background-color: white;
  border-top: 1px rgba(34, 34, 34, 0.2) solid;
  padding: 16px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
}
@media not all and (min-width: 768px) {
  .sp-container .p-btn-unit {
    text-align: center;
    margin-bottom: calc( 8px * 10 );
  }
}

.c-notes-box {
  background-color: #F9F9F9;
  padding: 32px 56px;
  margin-bottom: 32px;
}
@media not all and (min-width: 768px) {
  .c-notes-box {
    padding: 32px 24px;
  }
}
@media not all and (min-width: 600px) {
  .c-notes-box {
    margin-bottom: 16px;
  }
}
.c-notes-box .c-notes-ttl {
  color: #1847c7;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.6;
  margin-bottom: calc( 8px * 3 );
}
.c-notes-box .c-notes-txt {
  color: #222;
  font-size: 16px;
}

.g-slide {
  margin: 15px 0 20px 0;
  padding-bottom: 50%;
  padding-top: 65px;
  position: relative;
  overflow: hidden;
}
.g-slide iframe,
.g-slide img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.g-slide + h3 {
  margin-top: calc( 8px * 15 );
}

.c-anc-unit {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 0 56px;
  padding: 0;
}
@media not all and (min-width: 768px) {
  .c-anc-unit {
    gap: 0 40px;
  }
}
@media not all and (min-width: 600px) {
  .c-anc-unit {
    padding: 0;
  }
}
.c-anc-unit li {
  width: calc(50% - 28px);
}
@media not all and (min-width: 768px) {
  .c-anc-unit li {
    width: calc(50% - 20px);
  }
}
.c-anc-unit .anc-link {
  border-bottom: 1px #1847c7 solid;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: clamp(16px, 1.25vw + 4px, 20px);
  font-weight: 700;
  padding: 0 24px 16px 0;
  text-decoration: none;
  line-height: 1.3;
  position: relative;
}
@media not all and (min-width: 768px) {
  .c-anc-unit .anc-link {
    padding: 0 16px 24px 0;
  }
}
.c-anc-unit .anc-link::after {
  background: url(../img/common/icon_arrow_right_blue.svg) no-repeat 50%;
  background-size: 100% auto;
  content: "";
  display: flex;
  align-items: center;
  height: 20px;
  width: 24px;
  position: absolute;
  top: 8px;
  right: 0;
  transform: rotate(90deg);
}
@media not all and (min-width: 768px) {
  .c-anc-unit .anc-link::after {
    height: 15px;
    width: 18px;
  }
}
.c-anc-unit .anc-link:hover {
  color: #222;
}
.c-anc-unit.col4 {
  gap: 0 20px;
}
@media not all and (min-width: 768px) {
  .c-anc-unit.col4 {
    gap: 0 10px;
  }
}
.c-anc-unit.col4 li {
  display: flex;
  width: calc(25% - 15px);
}
@media not all and (min-width: 768px) {
  .c-anc-unit.col4 li {
    width: calc(25% - 8px);
  }
}
@media not all and (min-width: 600px) {
  .c-anc-unit.col4 li {
    width: calc(50% - 8px);
  }
}

#work .page-header .jumbotron-fluid::before {
  top: auto;
  bottom: 0;
  transform: translateY(0);
}
#work .page-header .jumbotron-fluid .col-right .col-img {
  height: clamp(280px, 16vw + 132.8px, 440px);
}
@media not all and (min-width: 768px) {
  #work .page-header .jumbotron-fluid .col-right .col-img {
    height: auto;
  }
}

/* 20241114追加 */
.myBlockCategory__group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.myBlockCategory__group li {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.417;
}
@media not all and (min-width: 768px) {
  .myBlockCategory__group li {
    font-size: 10px;
  }
}
.myBlockCategory__group + .myBlockCategory__group {
  margin-top: 7px;
}
@media not all and (min-width: 768px) {
  .myBlockCategory__group + .myBlockCategory__group {
    margin-top: 4px;
  }
}
.myBlockCategory__group.-group01 {
  gap: 4px;
}
@media not all and (min-width: 768px) {
  .myBlockCategory__group.-group01 {
    gap: 2px;
  }
}
.myBlockCategory__group.-group01 li {
  background-color: white;
  border: 1px solid #6D6D6D;
  color: #6D6D6D;
  padding: 4px 8px;
}
@media not all and (min-width: 768px) {
  .myBlockCategory__group.-group01 li {
    padding: 2px 6px;
  }
}
.myBlockCategory__group.-group01 li.-blue {
  background-color: #1847c7;
  border-color: #1847c7;
  color: white;
}
.myBlockCategory__group.-group01 li.-black {
  background-color: #222;
  border-color: #222;
  color: white;
}
.myBlockCategory__group.-group02 {
  gap: 0 12px;
}
@media not all and (min-width: 768px) {
  .myBlockCategory__group.-group02 {
    gap: 0 10px;
  }
}
.myBlockCategory__group.-group02 li {
  color: #929292;
  position: relative;
}
.myBlockCategory__group.-group02 li::before {
  content: "#";
  display: inline-block;
}
.myBlockCategory__group.-group02 li.-map::before {
  display: none;
}
.myBlockCategory__gMap {
  color: #929292;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.417;
  padding-left: 14px;
  text-decoration: none;
  position: relative;
}
@media not all and (min-width: 768px) {
  .myBlockCategory__gMap {
    font-size: 10px;
    padding-left: 10px;
  }
}
.myBlockCategory__gMap::before {
  background: url(../img/common/icon_map.svg) no-repeat center center;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 15px;
  width: 10px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media not all and (min-width: 768px) {
  .myBlockCategory__gMap::before {
    height: 10px;
    width: 7px;
  }
}

.myBlockTag {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 12px;
}
@media not all and (min-width: 768px) {
  .myBlockTag {
    gap: 4px;
  }
}
.myBlockTag__item {
  border: 1px transparent solid;
  color: white;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  padding: 4px 8px;
}
@media not all and (min-width: 768px) {
  .myBlockTag__item {
    padding: 5px 6px;
    font-size: 10px;
  }
}
.myBlockTag__item.-blue {
  background-color: #1847c7;
  border-color: #1847c7;
  color: white;
}
.myBlockTag__item.-black {
  background-color: #222;
  border-color: #222;
  color: white;
}
.myBlockTag__item.-white {
  background-color: white;
  border: 1px #E5E5E5 solid;
  color: #222;
}

.myBlockResult {
  margin-bottom: 32px;
}
@media not all and (min-width: 768px) {
  .myBlockResult {
    margin-bottom: 30PX;
  }
}
.myBlockResult__num {
  margin-bottom: 20px;
}
.myBlockResult__num-text {
  color: #1847c7;
  display: flex;
  align-items: baseline;
  gap: 0 2px;
  font-family: "Lato", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  position: relative;
}
.myBlockResult__num-text::after {
  color: #000;
  content: "件の求人";
  display: inline-block;
  font-family: "Noto Sans JP", -apple-system, BlinkMacSystemFont, "メイリオ", Meiryo, "Hiragino Kaku Gothic ProN", sans-serif;
  font-size: 14px;
  font-weight: 600;
}
.myBlockResult__tag {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  gap: 0 8px;
  position: relative;
}
@media not all and (min-width: 768px) {
  .myBlockResult__tag {
    gap: 10px 0;
    flex-wrap: wrap;
  }
}
.myBlockResult__tag span,
.myBlockResult__tag button {
  display: inline-block;
  vertical-align: middle;
}
.myBlockResult__tag-header {
  line-height: 1;
  width: 100px;
}
@media not all and (min-width: 768px) {
  .myBlockResult__tag-header {
    line-height: normal;
    width: 100%;
  }
}
.myBlockResult__tag-title {
  color: #000;
  font-size: 14px;
}
.myBlockResult__tag-body {
  box-sizing: border-box;
  display: flex;
  gap: 8px 6px;
  flex-wrap: wrap;
  width: calc(100% - 116px);
  position: relative;
}
@media not all and (min-width: 768px) {
  .myBlockResult__tag-body {
    gap: 4px;
    width: 100%;
  }
}
.myBlockResult__tag-item {
  border: 1px solid #516894;
  display: flex;
  align-items: center;
  gap: 0 3px;
  justify-content: space-between;
  padding: 5px 10px;
  padding-right: 0;
}
.myBlockResult__tag-text {
  color: #516894;
  font-size: 12px;
}
@media not all and (min-width: 768px) {
  .myBlockResult__tag-text {
    font-size: 11px;
  }
}
.myBlockResult__tag-delete {
  height: 20px;
  width: 20px;
  position: relative;
}
.myBlockResult__tag-delete::before, .myBlockResult__tag-delete::after {
  background-color: #516894;
  content: "";
  display: block;
  height: 1px;
  width: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.myBlockResult__tag-delete::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.myBlockResult__tag-delete::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.myBlockResult__tag-reset {
  margin-top: 2px;
  width: 100%;
}